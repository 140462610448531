const debugModule = require('debug')
const { H, nav, document, register, genid } = require('./common')
const EventEmitter = require('eventemitter3')

class Param extends EventEmitter {
  constructor (label, options = {}) {
    super()
    this.originalOptions = options
    this.addedToDOM = []
    this.label = label
    this.title = label // deprecated

    this.id = options.id
    if (!this.id) this.id = mkid(label)
    this.debug = debugModule(`visiparam/param:${this.id}`)
    
    if (this.optionDefaults) Object.assign(this, this.optionDefaults)
    if (options) Object.assign(this, options)
    
    if (this.enableIf) this.showIf = this.enableIf // backward compat
    this._show = !this.showIf // no-show if showIf is defined
    
    // accept this.classes as array of names or this.class as 1 or
    // more space-separate class names.
    if (!this.classes) {
      this.classes = []
      if (this.class) {
        this.classes = this.class.split(/\s+/).filter(x => x != '')
      }
    }

    nav.init()
    const fromURL = nav.state[this.id]
    if (fromURL) {
      this.current = fromURL
    } else {
      // we could have get current() do this on demand, but ... nah,
      // that doesn't really help with anything. Trying to solve the
      // problem of setting state before the allowedValues are all
      // known.
      this.current = this.default
    }

    this.watchNav()
    if (nav.domReady) {
      this.addToDOM()
    } else {
      nav.on('dom-ready', () => {
        this.debug('dom ready, addedToDOM = %o', this.addedToDOM)
        if (this.addedToDOM.length === 0) this.addToDOM()
      })
    }
  }

  // the "default" value is set by the user by passing
  // options.default, or setting this.default on the instance. If they
  // don't do that, each subclass has its own default value, available
  // via the defaultDefault() function, which may depend on other values.
  
  get default () {
    if (this.hasOwnProperty('_default')) return this._default
    return this.defaultDefault()
  }

  set default (value) {
    this._default = value
  }

  get bestCurrent () {
    if (this.clamped) return this.default
    if (this._current === undefined) {
      console.error('interal logic error, _current undef in bestCurrent')
      return this.default
    }
    return this._current
  }
  
  get current () {
    if (this.clamped) {
      console.error('.current read when clamped (value out of range)')
      return this.default
    } else if (this._current === undefined) {
      console.error('interal logic error, _current undef in .current')
      return this.default
    }
    return this._current
  }

  set current (next) {
    if (next === undefined) next = this.default
    const newValue = this.internalize(next)
    this.debug('setting param %o to %o', this.id, newValue)

    if (newValue === undefined) {
      this.clamped = true
      this._desiredValue = next
      return
    } else {
      delete this.clamped
      delete this._desiredValue
    }
    
    if (newValue === this._current) {
      this.debug('.. no change')
      return
    }

    // update ourself
    this._current = newValue

    // update everyone else
    this.debug('notifying listeners')
    this.changed()
    this.debug('done with notifying listeners')
  }

  limitsChanged (from) {
    // maybe change rendering?   leave that up to subclass
    if (this.clamped) {
      this.debug('trying to clamp')
      this.current = this._desiredValue
    } else {
      // these occur during object construction
      if (!from) return
      if (this._current === undefined) return
      
      this.debug('re-internalize with new limits')
      this.current = this.externalize(this.current)
    }
  }

  changed () {
    const value = this.current
    this.previousValue = value

    let ext = this.externalize(value)
    if (value === this.default) ext = ''
    let was = nav.state[this.id]
    if (was === undefined) was = ''

    // update the URL
    if (was !== ext) {
      const overlay = {}
      this.debug('updating nav state for %o to %o (%o => %o)',
            this.id, value, was, ext)
      overlay[this.id] = ext
      nav.jump(overlay, { noHistory: !this.history })
    }
    
    if (this._changing) throw Error('event recursion in param: ' + this.id)
    this._changing = true
    this.emit('change', value)
    delete this._changing
  }

  watchNav () {
    nav.on('change-' + this.id, ({key, oldValue, newValue}) => {
      this.debug('nav.change emited for', {id: this.id, key, oldValue, newValue})
      // If this came from us changing out own URL, this will loop
      // back and attempt a second change to become the same value,
      // but that's okay since it's the same value, the set() will
      // stop there.
      this.current = newValue
    })
  }
  
  addToDOM (parents) {
    if (!parents) {
      parents = `[data-title="${this.label}"]`  // deprecated
      parents += `, [data-label="${this.label}"]`
      parents += this.classes.map(x => `, .${x}`).join('')
    }
    if (typeof parents === 'string') {
      parents = document.querySelectorAll(parents)
    }
    if (!parents.values && !Array.isArray(parents)) {
      parents = [parents]
    }

    if (this.id === 'maxNodes') console.error('about to addToDOM, parents = %O', parents)

    for (const parent of parents) {
      this.debug('adding to dom under %o', parent)
      if (this.id === 'maxNodes') console.error('added to dom')
      const div = document.createElement('div')
      div.style.marginTop = '1rem'
      div.style.display = this._show ? '' : 'none'
      div.innerHTML = this.html
      this.bindToDOM(div)
      
      parent.append(div)
      this.addedToDOM.push(div)
    }
    // this.changed()   WHy was this here???
  }

  /*
      const input = div.querySelector('input')
      
      input.addEventListener('change', ev => {
        this.current = parseBoolean(ev.target.checked)
      })

      this.on('change', () => {
        input.checked = this.current
      })
    }
    this.changed()
  }
  */

  removeFromDOM () {
    this.debug('removing from dom at %o', this.addedToDOM)
    for (const div of this.addedToDOM) {
      div.remove()
    }
  }

  close () {
    this.removeFromDOM()
    this.emit('close')
    this.removeAllListeners()
  }

  set show (newValue) {
    newValue = !!newValue
    if (newValue === this._show) return
    this._show = newValue
    for (const div of this.addedToDOM) {
      div.style.display = newValue ? '' : 'none'
    }
  }
  
  // PROBABLY OVERRIDE THIS
  internalize (string) {
    return string
  }

  // MAYBE OVERRIDE THIS
  externalize (value) {
    if (value.name) return value.name
    return '' + value
  }

  
  // YOU MUST OVERRIDE THIS
  get html () {
    return H`<b>Missing visiparam customization: ${this.title}</b>`
  }
  // (return DOM element or array of DOM elements to add)
  // createElement () {
  // return document.createTextNode('Missing visiparam customization')
  // }
}

function mkid (str) {
  str = str.replace('%', 'pct')
  str = str.replace('#', 'num')
  str = str.replace('?', '')
  str = str.toLowerCase()
  str = str.replace(/ ([a-zA-Z])/g, (match, p1) => p1.toUpperCase())
  str = str.replace(/[^a-zA-Z0-9]/g, '_')
  return str
}

module.exports = { Param }

