import React from 'react'
import { useParam, params as globalParams } from './params'

globalParams.string('Active modal dialogs', { id: 'modals' })

const delim = '-'

// global cheat for escape key handling; this ends up set to the most
// recently defined close() action
let escapeKeyAction = () => {}
document.addEventListener('keydown', function (event) {
  var e = event || window.event
  if (e.keyCode === 27) escapeKeyAction()
})

export function useModals (params) {
  if (!params) params = globalParams // a bit error prone?
  const [names, setNames] = useParam('modals')
  const nameList = names.split(delim)
  console.log('useModals running, nameList = ', JSON.stringify(nameList))
  if (nameList[0] === '') nameList.shift()
  const name = nameList[nameList.length - 1]

  const close = event => {
    /*
    console.log('closeModal event %O', event)
    console.log('.. target %O', event.target)
    console.log('.. dc %O', event.dispatchConfig)
    console.log('.. native %O', event.nativeEvent)
    */
    if (event) event.preventDefault()
    console.log('Pops closing, nameList = ', JSON.stringify(nameList))
    nameList.pop()
    // console.log('closing, setting to', nameList.join(delim))
    if (nameList.length === 0) {
      document.documentElement.classList.remove('is-clipped')
    }
    setNames(nameList.join(delim))
  }
  escapeKeyAction = close
  
  const open = name => {
    nameList.push(name)
    // console.log({popNames, nameList})
    document.documentElement.classList.add('is-clipped')
    setNames(nameList.join(delim))
  }

  const currentModal = name
  return { openModal: open, closeModal: close, currentModal }
}

// only renders if name= current modal name

export function Modal (props) {
  let {name, params} = props
  if (!params) params = globalParams
  const { closeModal, currentModal } = useModals(params)

  if (name !== currentModal) return []  // is this a dangerous early return?

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      {React.cloneElement(props.children, { closeModal })}
    </div>
  )
}

export function ModalCard (props) {
  let { title, closeModal, children, buttons } = props
  if (!closeModal) closeModal = escapeKeyAction
  if (!buttons) {
    buttons = <button type="button" className="button" onClick={closeModal}>OK</button>
  }
  return (
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{title || 'Details'}</p> 
             <button type="button" className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            
            <section className="modal-card-body">
              {children}
            </section>
            <footer className="modal-card-foot">
              <div className="buttons">
                {buttons}
              </div>
            </footer>
          </div>
  )
}
