const debug = require('debug')('visiparam/number')
const { Param } = require('./param')
const { H, nav, document } = require('./common')


// Do my own scaling for step?
// Looks like step doesn't workin FF ?!

// Use type=number AND type=range tied together?

class Number extends Param {
  get optionDefaults () {
    return {
      min: 0,
      max: 100,
      step: 1
    }
  }

  defaultDefault () {
    return this.min
  }
 
  internalize (s) {
    let c = s
    if (typeof c !== 'number') c = parseFloat(c)
    if (c === undefined || isNaN(c)) c = this.default
    if (c < this.min) c = this.min
    if (c > this.max) c = this.max
    const steps = Math.round((c - this.min) / this.step)
    c = this.min + steps * this.step
    
    debug('internalized %o to %o', s, c)
    return c
  }

  moveToNext () {
    // set current () ends up calling internal which will clamp the value
    this.current = this.current + this.step
  }

  moveToPrev () {
    this.current = this.current - this.step
  }

  get html () {
    // console.log('visiparam number H = %O', H) WEIRD BUG - REACT
    //   complains H is not a function -- something about babel?
    
    const digits = 1 + ('' + this.max).length  // the spinner takes about an em
    return `       <label>${this.title}:</label>
       <button style="padding: 0" class="tinyButton down10" title="Move down 10">--</button>
       <button style="padding: 0" class="tinyButton down" title="Move down 1">-</button>
       <button style="padding: 0" class="tinyButton def" title="Return to default">D</button>
       <button style="padding: 0" class="tinyButton up" title="Move up 1">+</button>
       <button style="padding: 0" class="tinyButton up10" title="Move up 10">++</button>
       <br/>
       <input style="width: ${digits}em;" type="text" class="number" min="${this.min}" max="${this.max}" value="${this.current}" />
       <span>${this.min}</span>
       <input class="range" type="range" id="${this.id}" name="${this.id}" min="${this.min}" max="${this.max}" step="${this.step}" value="${this.current}" />
       <span>${this.max}</span>
`
    //   <button style="padding: 0" class="about">?</button>
  }

  bindToDOM (div) {
    const input = div.querySelector('.range')
    const num = div.querySelector('.number')

    // div.querySelector('.down50').addEventListener('click', () => { this.current = this.current - (this.current - this.min) / 2 })
    div.querySelector('.down10').addEventListener('click', () => { this.current = this.current - 10 })
    div.querySelector('.down').addEventListener('click',   () => { this.current = this.current - this.step })
    div.querySelector('.def').addEventListener('click',    () => { this.current = this.default })
    div.querySelector('.up').addEventListener('click',     () => { this.current = this.current + this.step })
    div.querySelector('.up10').addEventListener('click',   () => { this.current = this.current + 10 })
    // div.querySelector('.up50').addEventListener('click',   () => { this.current = this.current + (this.max - this.current) / 2 })
    
    input.addEventListener('input', ev => {
      this.current = ev.target.value
    })

    // if this is 'input' and things are running slow (eg with debug
    // statements, at least) it acts like the arrow keys are held down
    // a lot.
    num.addEventListener('change', () => {
      this.current = num.value
    })
    
    this.on('change', () => {
      input.value = this.current
      num.value = this.current
    })
  }
}


module.exports = { Number }
