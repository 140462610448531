/**
   Alice, with a credibility score of sourceScore says that Bob has as
   credibility score of edgeScore.  What do we take Bob's credibility
   score to be, going forward, taking into account Alice's credibility
   in reporting that?

   There's no right answer, because 1-dimensional credibility scores
   are an oversimplification of trust. In particular, they are
   combining the probability that a source is trustworthy with an
   uncertainty around that probability estimation, ... or something
   weird like that.

   Use taper.run.js to output a table and taper.test.js for some more.
*/

function taper (sourceScore, edgeScore) {
  if (!sourceScore) sourceScore = 0
  if (!edgeScore) edgeScore = 0

  // r = rater, s = says, t = taper output
  // z numbers are zero based, scores (-1 .. 1) instead of (0 .. 1)

  const rz = (sourceScore - 0.5) * 2
  const sz = (edgeScore - 0.5) * 2

  let tz
  if (rz > 0) {
    tz = rz * sz
  } else {
    tz = 0
  }

  const t = (tz / 2) + 0.5
  return t
}

module.exports = { taper }
