import React from 'react'
import { params, useParam } from './params'
import { U } from './Nodes'

// import equal from 'fast-deep-equal'

params.string('Focus Node', { id: 'focusNode', history: true })

export default function ({client, layout}) {
  const [id] = useParam('focusNode')
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const node = id && client.gg.obtainNode(id)
  const payload = node && node.payload
  // const [payload, setPayload] = React.useState(id && node && node.payload)

  // console.log('rerender focusNode %o %o %o', id, node, payload)
  // if (node && node.ins) console.log('... ins: %o', [...node.ins])
  
  React.useEffect(() => {
    client.on('change', forceUpdate)  // ins and outs are not payload
    client.gg.on('setNodePayload', forceUpdate)
    return () => {
      client.off('change', forceUpdate)
      client.gg.off('setNodePayload', forceUpdate)
    }
  }, [forceUpdate, client])

  if (node) {
    layout.set('Node', 'show', true)
  } else {
    layout.set('Node', 'show', false)   // maybe?   I dunno, okay to leave gray, too
  }
  
  const style = { background: '#ccc', height: '100%' }

  if (!node) {
      return (
      <div style={style}>
      <p style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'gray', fontSize: '200%'}}>Select an item above to view detail here</p>
      </div>
    )
  }
  

  // -- skip this for now, to help with debugging
  // console.log('watching focus node %O', node)
  // client.watchNode(node)

  
  return (
    <div style={style}>
      <button style={{float: 'right'}} onClick={() => params.data('focusNode', '')}>✖️</button>
      <Node id={id} node={node} />
      <Vote node={node} client={client} nodeid={node.id} />

      { [...(node.ins || [])].map(([source, edgePayload]) => <pre key={source.id} style={{border: '1rem solid #ccc'}}>Rated by <U node={source} /> : {JSON.stringify(edgePayload, null, 0)}</pre>) }
      
      { [...node.outs].map(([targetid, edgePayload]) => <pre key={targetid} style={{border: '1rem solid #ccc'}}>Rated <U node={client.gg.obtainNode(targetid)} /> : {JSON.stringify(edgePayload, null, 0)}</pre>) }
      
      <pre style={{border: '1rem solid #ccc'}}>JSON details: {'\n' + JSON.stringify(payload, null, 2)}</pre>
    </div>
  )
}

// would it be better with a modal?

function Vote ({node, nodeid, client}) {
  // console.log('VOTE rendering')
  const setScore = async n => {
    // node.data('score', n)
    // HA, that's not it.   It's about the edge!
    //
    // const edge = client.graph.setEdge(client.user.nodeid, node.id(), n, 'manually set')
    //
    // Um, that's not right either -> it needs to go via the server, by being
    // added to client.user.edges
    const e = {target: node.id, score: n, prov: 'manually set'}
    if (n === 'none') e.delete = true
    await client.setUserEdge(e)
  }
  const [edge, setEdge] = React.useState(client.getUserEdge(node.id))
  const thisText = (edge && edge.reason) || ''
  const [text, setText] = React.useState(thisText)

  React.useEffect(() => {
    const e = client.getUserEdge(node.id)
    console.log('effect running e = %o', e)
    setEdge(e)
    setText((e && e.reason) || '')
  }, [node, client])
  
  return (
    <div>
      <p>Score: {node.data('score') || 'none'}</p>
      <button className="button is-small" onClick={() => setScore(0.01)}>Dangerous</button>
      <button className="button is-small" onClick={() => setScore(0.20)}>Not Credible</button>
      <button className="button is-small" onClick={() => setScore(0.40)}>Bad Feeling</button>
      <button className="button is-small" onClick={() => setScore('none')}>No Opinion</button>
      <button className="button is-small" onClick={() => setScore(0.60)}>Barely</button>
      <button className="button is-small" onClick={() => setScore(0.70)}>Fair</button>
      <button className="button is-small" onClick={() => setScore(0.80)}>Credible</button>
      <button className="button is-small" onClick={() => setScore(0.90)}>High</button>
      <button className="button is-small" onClick={() => setScore(0.99)}>Self</button>
      <p>
        <label>Reason: </label><input id="reason" type="text" value={text} size="60" onChange={event => setText(event.target.value)} />
        <button onClick={event => {
          // const text = document.getElementById('reason').value
          // console.log(text)
          client.setUserEdge({target: node.id, reason: text})
        }}>Save</button>
      </p>
    </div>
  )
}

function Node ({id, node}) {
  const rows = []

  rows.push(['id', id])

  let v
  v = node.data('screenName')
  if (v) rows.push(['Screen Name', v])
  
  const tw = node.data('twitterAccount')
  if (tw && tw.screen_name) {
    rows.push(['Twitter account', <a href={'https://twitter.com/' + tw.screen_name}>{tw.screen_name}</a>])
  }
  
  return (
    <table>
      <tbody>
        {rows.map(([key, value]) => (
          <tr key={key}>
            <th>{key}</th>
            <td>{value}</td>
          </tr>
        ))}
    </tbody>
    </table>
  )
}

// for walkability, we need .. cy graph?  maybe?
