const EventEmitter = require('eventemitter3')
const debug = require('debug')('visiparam/string')
const { nav, document, genid } = require('./common')
const { Param } = require('./param')

class String extends Param {
  defaultDefault () {
    return ''
  }

  get optionDefaults () {
    return {
      rows: 5,
      cols: 60
    }
  }

  get html () {
    const eid = genid()
    return `       
       <label for="${eid}">${this.title}</label><br />
       <textarea id="${eid}" rows="${this.rows}" cols="${this.cols}">${this.current}</textarea>
`
  }

  bindToDOM (div) {
    const input = div.querySelector('textarea')
      
    input.addEventListener('change', ev => {
      this.current = input.value
    })

    this.on('change', () => {
      input.value = this.current

      // if this is an append, then scroll to the bottom
      if (this.previousValue &&
          this.previousValue === this.current.slice(0, this.previousValue.length)) {
        input.scrollTop = input.scrollHeight
      }
    })
  }
}

module.exports = { String }
