import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { params } from './params'
import { Client } from 'osca-client'
import { LayoutController } from './layout'

const layout = new LayoutController()

const url = localStorage.birdcageURL || 'wss://s1.trustlamp.com/api'
const client = new Client({url})

client.on('login', () => {
  layout.hideAll()
  layout.show('Nodes')
})

client.on('logout', () => {
  layout.hideAll()
  layout.show('Welcome')
})

client.on('change-user-data', user => {
  if (typeof window.Canny === 'undefined') {
    console.warn('Canny not defined')
  } else {
    if (user) {
      window.Canny('identify', {
        appID: '5f47e77db3dab11e4319362e',
        user: {
          email: user.email,
          name: user.screenName,
          id: user.id,
          // avatarURL: user.avatarURL,
          created: new Date(user.cr_created).toISOString(),
        }
      })
    }
  }
})

const props = {client, layout, params}

ReactDOM.render(
  <React.StrictMode>
    <App {...props}/>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
