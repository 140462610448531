import React from 'react'
import { Heading, Section } from 'react-bulma-components'

function P ({children}) {
  return <p style={{marginTop: '1rem'}}>{children}</p>
}

export default function How () {
  return (
    <Section>
      <Heading>Who Should People Believe?</Heading>

      <P>That is the question we aim to solve.</P>
      <P>There is no right answer for everybody. Each person has different needs and goals.  Each person has different people they start out trusting.</P>
      <P>Still, no one wants to be fooled (except Penn &amp; Teller).  Everyone wants to know who they can safely believe.</P>
      <P>Our approach is to help people work together, collaborating in deciding who to trust. Given who you already trust, we help you figure out whether it makes sense to trust each other potential source.</P>
      
      <Section>
        <Heading size={5}>How it works</Heading>
        <ol>
          <li>Tell us your social media accounts (only Twitter for now)</li>
          <li>We'll scan to see who you seem to trust, and who they seem to trust (recursively). We're not looking at the words people say, just follows/likes/RTs.</li>
          <li>We'll show you the results, in a table or a diagram.</li>
          <li>If they don't seem right, we'll help you identify the problem</li>
          <li>Use your knowledge of people and facts to correct the system, if needed.</li>
          <li>Share this app with people you care about to help guide their trust decisions</li>
          <li>Check back to see where there might disagreement or people wanting more information</li>
        </ol>
      </Section>
    </Section>
  )
}

