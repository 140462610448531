import React from 'react';
import EventEmitter from 'eventemitter3';

/*
export function useLayoutController () {
  
}
*/

/*
  Panels stack from the top down, taking height "size", if
  show is truthy.

  Soon lets have { sidebar: right } which puts that panel on the
  side, taking "size" width
*/

let instanceCount = 0

export class LayoutController extends EventEmitter {
  constructor (options = {}) {
    super()
    this.instanceCount = instanceCount++
    this.height = 800 // for now -- use BoundingBox of parent?
    Object.assign(this, options)
    if (!this.panels) this.panels = {}
    this.run()
    window.addEventListener('resize', () => { this.apply() })
  }
  addPanel (props) {
    if (this.panels[props.name]) return
    this.panels[props.name] = {...props}
    this.run()
  }
  toggleShow (panelName) {
    const panel = this.panels[panelName]
    panel.show = !panel.show
    this.apply()
  }
  show (panelName, state = true) {
    const panel = this.panels[panelName]
    panel.show = state
    this.apply()
  }
  hideAll () {
    for (const panel of Object.values(this.panels)) {
      panel.show = false
    }
    this.apply()
  }
  set (panelName, key, value) {
    this.panels[panelName][key] = value
    this.apply()
  }
  hasRoom (panelName) {
    const panel = this.panels[panelName]
    return (panel.show && panel.esize)
  }
  pos (panelName) {
    const panel = this.panels[panelName]
    if (panel && panel.show && panel.esize) {
      const css = {
        display: 'block',
        position: 'absolute',
        overflow: 'auto',
        top: this.y(panel.top),
        width: '100%',
        height: this.y(panel.esize),
        left: this.x(panel.left),
        // border: '1px solid gray',
        // backgroundColor: 'blue'
        boxSizing: 'border-box'
      }
      // console.log('css: %O', css)
      return css
    } else {
      return { display: 'none' }
    }
  }
  y (n) {
    return `${n}px`
  }
  x (n) {
    return `${n}px`
  }
  * shownPanels () {
    for (const panel of Object.values(this.panels)) {
      if (panel.size === 0) continue
      if (panel.show) yield panel
    }
  }
  /* The esize (effective size) is the size if the size is set,
     otherwise it's a portion of the remaining size.  Key feature
     is we DONT steal space from earlier, so that border dragging
     only steals from later */
  setESizes () {
    let sizeUsed = 0
    const panels = [...this.shownPanels()]
    for (let i = 0; i < panels.length; i++) {
      const panel = panels[i]
      if (panel.size) {
        panel.esize = panel.size
      } else {
        const numLeft = panels.length - i
        panel.esize = Math.floor((this.height - sizeUsed) / numLeft)
        if (panel.esize < 20) panel.esize = 0
      }
      sizeUsed += panel.esize + 10
    }
    sizeUsed -= 10
  }
  run () {
    this.setESizes()
    let p = 0
    for (const panel of this.shownPanels()) {
      panel.top = p
      // panel.bottom = (this.height - panel.top) - panel.esize
      //console.log('layout running: name %o p %o height %o top %o esize %o bottom %o', panel.name, p, this.height, panel.top, panel.esize, panel.bottom)
      p += panel.esize + 10
      panel.left = 0
      panel.right = 0
    }
    for (const panel of this.shownPanels()) {
      // pretty hacky way to tell the panels when they need to resize internally :-(
      this.emit('setHeight', panel.name, panel.esize)
    }
      // console.log('layout.run complete %O', this)
  }
  getHeight (name) {
    return this.panels[name].esize
  }
  apply () {
    // console.log('APPLY LAYOUT')
    const cont = document.getElementById('Panelist')
    if (!cont) return // not ready yet
    // this.height = window.innerHeight
    // this.width = window.innerWidth
    const rect = cont.getBoundingClientRect()
    this.height = rect.height
    this.width = rect.width
    this.run()
    for (const panel of Object.values(this.panels)) {
      const elem = document.getElementById(panel.name)
      if (elem) {
        const css = this.pos(panel.name)
        for (const [key, value] of Object.entries(css)) {
          elem.style[key] = value
        }
      }
    }
  }
}

export function Panelist ({layout, children}) {
  for (const child of children) layout.addPanel({
    name: child.props.name,
    size: child.props.size,
    side: child.props.side,
    show: child.props.show
  })

  for (const child of children) {
    if (child.props.layout !== layout) console.warn('different layout')
  }
  // console.log('Panelist set up, layout=%O', layout)
  return (
    <div id="Panelist" style={{
      position: 'absolute',
      top: '3rem',
      right: 0, // '3rem',
      bottom: '1rem',
      left: 0, // '3rem',
      // border: '1px solid green'
    }}>
      {children}
    </div>
  )
}

export function Panel ({layout, name, children}) {
  const style = {} // later .... layout.pos(name)
  // close box, etc...
  return <div id={name} style={style}>{children}</div>
}
