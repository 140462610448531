const common = require('./common')
const yargs = require('./yargs')
const EventEmitter = require('eventemitter3')

const boolean = require('./boolean')
const string = require('./string')
const number = require('./number')
const pointer = require('./pointer')

class Registry extends EventEmitter {
  constructor () {
    super()
    // console.error('visiparam registry constructed %o', this)
    this._items = new Set()

    // These are just objects {}, but I accidentally called it like
    // a function, and this way you can use it either way:
    //    params.label['foo'] or params.label.foo or params.label('foo')
    this.id = arg => { return this.id[arg] }
    this.label = arg => { return this.label[arg] }
  }

  *[Symbol.iterator] () {
    for (const item of this._items) yield item
  }

  create (cls, args) {
    const item = new cls(...args)

    // new style
    if (this.label.hasOwnProperty(item.title)) {
      throw Error('conflicting param label: ' + JSON.stringify(item.title))
    }
    if (this.id.hasOwnProperty(item.id)) {
      throw Error('conflicting param id: ' + JSON.stringify(item.id))
    }
    this.label[item.title] = item
    this.id[item.id] = item

    // old style - deprecated
    if (this.hasOwnProperty(item.title)) {
      throw Error('conflicting param title: ' + JSON.stringify(item.title))
    }
    if (this.hasOwnProperty(item.id)) {
      throw Error('conflicting param id: ' + JSON.stringify(item.id))
    }
    this[item.title] = item
    this[item.id] = item
    
    this._items.add(item)

    item.on('close', () => {
      this._items.delete(item)
      delete this.label[item.title]
      delete this.id[item.id]
      delete this[item.title]
      delete this[item.id]
    })

    // the factory emits 'change' if any of its params changes
    item.on('change', () => {
      this.checkRules()
      this.emit('change', item)
    })
    this.checkRules()
    
    return item
  }
  
  boolean (...args) { return this.create(boolean.Boolean, args) }
  string (...args) { return this.create(string.String, args) }
  number (...args)  { return this.create(number.Number, args) }
  pointer (...args) { return this.create(pointer.Pointer, args) }

  addToYargs (...args) { return yargs.addToYargs(this._items, ...args) }

  // good for saving a copy, testing, running simulations
  deadClone () {
    const clone = {
      // a more thorough version might run the actual code, but set an
      // initial value as if it came from the URL or command line.
      boolean: () => {},
      string: () => {},
      number: () => {},
      pointer: () => {}
    }
    for (const param of this) {
      const pp = {
        title: param.title,
        id: param.id,
        current: param.current
      }
      clone[param.title] = pp
      clone[param.id] = pp
      clone.data = arg => { return clone[arg] }
      clone.setFromObject = obj => {
        for (const [key, value] of Object.entries(obj)) {
          this.data(key, value)
        }
      }
    }
    return clone
  }

  /**
     Note that clones sync their state through the URL if you
     don't detach them from the URL!
  */
  clone () {
    const n = new Registry()
    for (const param of this) {
      // console.log('cloning param %o %o', param.constructor.name, param.label)
      const p = n.create(param.constructor, [param.label, param.originalOptions])
      // console.log('clone with param %O', p)
    }
    return n
  }

  setFromObject (obj) {
    for (const [key, value] of Object.entries(obj)) {
      this.data(key, value)
    }
  }

  checkRules () {
    for (const param of this) {
      if (param.allowedValuesFunction) {
        param.allowedValues = param.allowedValuesFunction()
      }
      if (param.showIf) {
        param.show = param.showIf(this)
      }
    }
  }

  get (key) {
    let p = this.label[key]
    if (p) return p
    p = this.id[key]
    if (p) return p
    throw Error('no param with label or id ' + JSON.stringify(key))
  }
  
  data (...args) {
    if (args.length === 1) {
      const [key] = args
      const p = this.get(key)
      return p.current
    } else if (args.length === 2) {
      const [key, value] = args 
      const p = this.get(key)
      const oldValue = p.current
      p.current = value
      return oldValue
    } else throw Error('bad parameters to visiparam.data()')
  }

  /**
     Return a simplified object that has an entry for each param {
     paramId: paramCurrent ... } and which we henceforth update in
     place whnever any of the current values change. Arguably we
     should return an object with getters and setters instead. Could
     also use a proxy. 

     No way to turn it off, but we only ever make one per Registry, so
     it's probably fine.
  */
  idView () {
    if (!this._idView) {
      const v = {}
      this._idView = v
      
      for (const param of this) {
        v[param.id] = param.current
      }
      this.on('change', param => {
        v[param.id] = param.current
      })
    }
    return this.idView
  }
}

const globalRegistry = new Registry()

const vp = globalRegistry
vp.globalRegistry = globalRegistry
vp.Registry = Registry

// put all our sub-module stuff on this module, in case you want
// to use stuff more directly
//
// short for vp.Pointer = pointer.Pointer, etc
Object.assign(vp, pointer, number, boolean, string)

// well, only some of 'common'.  Specifically, this stuff which lets
// browser apps sometimes run okay in node
vp.nav = common.nav
vp.document = common.document
vp.H = common.H

module.exports = vp
