import React from 'react'
import classnames from 'classnames'
import { brand } from './branding'
import { useModals } from './modals'
import { useUser } from './User'

export default function ({layout, params, client}) {
  const [active, setActive] = React.useState()
  const { openModal } = useModals(params)
  const user = useUser(client)

  let userButtonBody = 'Login'
  if (user) {
    // console.log('user = %O', user)
    userButtonBody = user.screenName || 'No Username'
  }

  
  /*
  const activeStyle = {
    fontWeight: "bold",
    borderBottom: "3px solid blue"
  }
  */

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={brand.logo} alt="Trust" width="28" height="28" />
          <strong style={{paddingLeft: '0.5rem'}}>{brand.name}</strong>
        </a>
        <div
          role="button" aria-label="menu" aria-expanded="false"
          className={classnames('navbar-burger', {'is-active': active})}
          onClick={e => setActive(!active)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div className={classnames('navbar-menu', {'is-active': active})}>
        <div className="navbar-start">
          {user && <button className="navbar-item button is-medium" onClick={() => layout.toggleShow('Nodes')}>Score Table</button>}
          { //<button className="navbar-item button is-medium" onClick={() => layout.toggleShow('Node')}>Node</button>
          }
          {user && <button className="navbar-item button is-medium" onClick={() => layout.toggleShow('Diagram')}>Diagram</button>}
        </div>
        <div className="navbar-end">
          <button className="navbar-item button is-medium" onClick={() => openModal('about')}>About</button>
          { user && <button className="navbar-item button is-medium" onClick={() => openModal('search')}>Search</button> }
          { user && <button className="navbar-item button is-medium" onClick={() => layout.toggleShow('Settings')}>Settings</button> }
          <button className={"navbar-item button is-medium" + (user ? '' : ' is-primary')} onClick={() => openModal('user')}>{userButtonBody}</button>
        </div>
      </div>
    </nav>
  )
}

 /*
          <button className="navbar-item button is-medium" onClick={() => layout.toggleShow('Invite')}>Invite</button>
          <button className="navbar-item button is-medium" onClick={() => openModal('profile')}>Profile</button>



          <NavLinq onClick={e => setActive(false)} className="navbar-item" to="/how" activeStyle={activeStyle}>How it works</NavLinq>
          <NavLinq onClick={e => setActive(false)} className="navbar-item" to="/n" activeStyle={activeStyle}>Scores</NavLinq>
          <NavLinq onClick={e => setActive(false)} className="navbar-item" to="/dia" activeStyle={activeStyle}>Diagram</NavLinq>
          <NavLinq onClick={e => setActive(false)} className="navbar-item" to="/feed" activeStyle={activeStyle}>Activity</NavLinq>
        </div>
        
        <div className="navbar-end">
          <NavLinq onClick={e => setActive(false)} className="navbar-item" to="/about" activeStyle={activeStyle}>About</NavLinq>
          <button onClick={() => { setActive(false); setShowSettings(!showSettings)}} className="navbar-item">Settings</button>
          <NavLinq onClick={e => setActive(false)} className="navbar-item" to="/user" activeStyle={activeStyle}>{user || "Login"}</NavLinq>
        </div>
            */ 

