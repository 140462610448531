import React from 'react'
import { params } from './params'
import { ReactTabulator, reactFormatter } from 'react-tabulator'

// visiparam.string('Focus Node', { id: 'focusNode' })

const cmp = (a, b) => {
  return b.payload.score - a.payload.score
}

const getNodes = client => {
  const n = [...client.gg.nodes()].filter(n => n.payload.score !== undefined)
  n.sort(cmp)
  // console.log('Nodes = %O', n)
  return n
}

// Total hack.  I need to call table.setHeight when the layout
// changes, but I can't figure out a clean way to get the table object
// from ReactTabulator. But formatters can find the table they are running
// int.  Clearly assumes we only have one Nodes table.

let table = null
let lastSetHeight = null

let counter = 0 // this is surely stupid- I'm SOOO working against React
// elsewhere I have a more elegant approach, but still dumb

export default function ({client, layout, name}) {
  const [count, setCounter] = React.useState(counter)
  // const [limit, setLimit] = React.useState(client.limit)

  React.useEffect(() => {
    const handleChange = () => { setCounter(++counter) }
    client.on('change', handleChange)
    return () => {
      client.off('change', handleChange)
    }
  }, [setCounter, client])

  /*
  React.useEffect(() => {
    client.limit = limit
  }, [limit, client])
  */

  /*
  if (client.loading) {
    return (
      <div style={style}>
        <p>Fetching data...</p>
      </div>
    )
  }
  */

  /*
  const st1 = {maxWidth: '30vw', whiteSpace: 'nowrap', overflow: 'hidden'}
  const st2 = {width: '20vw', whiteSpace: 'nowrap', overflow: 'hidden'}
  const st3 = {width: '20vw', whiteSpace: 'nowrap', overflow: 'hidden'}
  */

  const st1 = {}
  const st2 = {}
  const st3 = {}

  const sizeButton = 'mr-1 button is-small is-outlined'

  const nodes = getNodes(client)

  const buttons = [5, 10, 30, 100, 300, 1000, 3000, 10000]

  const titlePixels = 32
  
  const columns = [
    { title: 'Score', field: 'score', width: 75 },
    { title: 'Account', field: 'account', formatter: reactFormatter(<Account/>)}
    // { title: 'URL', field: 'actions', hozAlign: 'center', width: 100, formatter: reactFormatter(<Show/>)},
  ];

  const data = []
  for (const n of nodes) {
    data.push({score: pct(n.payload.score), node: n})
  }

  const h = (layout.getHeight(name) || 200) - titlePixels
  // lastSetHeight = h
  console.log('name=%o height=%o', name, h)
  const options = { height: h } // is the enough?
  const props = { columns, data, options }

  // how do I setHeight() and .redraw() with React Tabulator?
  //
  // how do I .off this, and handle friggin react calling 100 times?
  //
  const setHeight = (panelName, height) => {
    // console.log('setHeight() panel %o height %o, table = %o', panelName, height, table)
    if (panelName !== name) return
    if (table) {
      // http://tabulator.info/docs/4.7/layout#virtual-dom-redraw
      if (!table.setHeight) throw Error()

      const h = (layout.getHeight(name) || 200) - titlePixels
      if (h !== lastSetHeight) {
        table.setHeight(h)
        lastSetHeight = h
        console.warn('changing panel %o height %o, table = %o', panelName, h, table)
      } else {
        // console.warn('panel %o height %o, UNCHANGED table = %o', panelName, h, table)
      }
      // table.redraw()  -- or rely on Resize Observer?
    }
  }
  layout.on('setHeight', setHeight)
  // ugh - give layout a chance to set up
  setTimeout(() => setHeight(name), 1)
  
  return (
    <div style={{height: '100%'}}>
      <p style={{height: titlePixels}} className="mr-2 ml-4">{nodes.length} sources.  Max relationships:
        { buttons.map(
          (n) =>
            <button key={n} className={sizeButton} onClick={() => client.limit = n}>{n}</button>)} (larger=slower; reload if too slow)
      </p>
      <ReactTabulator {...props} />
    </div>
  )
}

function Account ({cell}) {
  // hack to give us access to the table instance
  const nowTable = cell.getTable()
  if (table === null) {
    table = nowTable
  } else if (table !== nowTable) {
    console.error('table instance changed, in Nodes.js')
    table = nowTable
  }
  
  const rowData = cell._cell.row.data;
  return <U node={rowData.node}/>
}

/*
      <table>
        <thead>
          <tr>
            <th>Score</th>
            <th style={st1}>Account</th>
            <th style={st2}>Reasons</th>
            <th style={st3}>Download Status</th>
          </tr>
        </thead>
        <tbody>
          {nodes.slice(0, 50).map(x => {
            // return <pre>{JSON.stringify(x, null, 2)}</pre>
            return ( // need key prop
              <tr key={x.id}>
                <td>{pct(x.data('score'))}</td>
                <td style={st1}><U node={x} /></td>
                <td style={st2}><Prov node={x} client={client}/></td>
                <td style={st3}><Cursors node={x} /></td>
              </tr>
            )
          })}</tbody></table>
    </div>
  )
}
*/

export function U ({node}) {
  const id = node.id
  const twitterAccount = node.data('twitterAccount')
  let name = id
  if (node.data('screenName')) name = node.data('screenName')
  if (twitterAccount) {
    name = `${twitterAccount.name} @${twitterAccount.screen_name}`
    if (name.length > 40) name = name.slice(0,37) + '...'
  }
  // placeholder until I remember how to get the right URL for that state
  return <a href={'#focus=' + id} onClick={e => {
    e.preventDefault();
    if (params.data('focusNode') === id) {
      params.data('focusNode', '')
    } else {
      params.data('focusNode', id)
    }
  }}>
           {name}
         </a>
}

function Prov ({node, client}) {
  const out = []
  const prov = node.data('scoring-table')
  if (!prov) return []
  // console.log(prov)
  for (const p of prov.data) {
    if (out.length > 0) {
      out.push(' [more...]') // link to prov details view
      break
    }
    const edge = client.graph.getEdge(p.edge.source, p.edge.target)
    out.push(<span key={edge.source().id}><U node={edge.source()} /> {edge.data('prov')}</span>) // JSON.stringify(edge.json()))
  }
  return out
}

function Cursors ({node}) {
  const twitterAccount = node.data('twitterAccount')
  if (!twitterAccount) return 'No twitter info'
  const cs = twitterAccount.cursors
  if (!cs) return 'not started'
  // console.log('cs = %O, typo  %o', cs, typeof cs)

  const out = []
  for (const facet of ['friendsList', 'likes', 'tweets']) {
    let facetName = facet
    if (facetName === 'friendsList') facetName = 'followed'
    const c = cs[facet]
    let items = c ? c.items : 0
    if (items === undefined) items = '??'  // items not properly maintained sometimes
    let ongoing = <span style={{color: 'green', fontWeight: 'bold'}}>+++</span>
    if (c && c.done) {
      ongoing = ''
    }
    out.push(<span key={facet}><span>{items}</span>{ongoing} <span style={{color: 'gray'}}>{facetName}</span> </span>)
  }
  return out
}

function pct (n) {
  if (typeof n === 'number' && !isNaN(n)) return Math.round(n * 100)
  return '—' // mdash
}

// BUGGY ---
//
// Will fetch much faster than it should if you switch roots a lot.
//

/*
{
  "group": "nodes",
  "id": "23556190",
  "score": 1,
  "prov": [
    {
      "comment": "credibility assessment",
      "edge": {
        "source": "You",
        "target": "23556190",
        "score": 1,
        "prov": "assumption since no givens were given"
      },
      "value": 1,
      "weight": 8.333333333333327,
      "fromNode": "You"
    }
  ],
  "twitterAccount": {
    "uis": "23556190",
    "screen_name": "sandhawke",
    "name": "Sandro Hawke",
    "location": "Waltham, MA",
    "url": "https://t.co/Lc22dSQaNm",
    "followers_count": 2858,
    "created_at": "Tue Mar 10 04:23:36 +0000 2009",
    "profile_image_url_https": "https://pbs.twimg.com/profile_images/720660030492684288/83KVJh6O_normal.jpg",
    "cursors": {
      "friendsList": {
        "done": true,
        "pagesDone": 13,
        "lastUsed": 1592434903737,
        "uis": "23556190",
        "name": "friendsList"
      },
      "likes": {
        "done": true,
        "pagesDone": 18,
        "lastUsed": 1592435004976,
        "uis": "23556190",
        "name": "likes"
      },
      "tweets": {
        "done": true,
        "pagesDone": 18,
        "lastUsed": 1592435062008,
        "uis": "23556190",
        "name": "tweets"
      }
    }
  }
}
*/
