import React from 'react'
// import { useParam } from './params'

// VERY restricted --- there should only be one of these,
//
// and it may be very expensive to render and unrender, so
// just make hide true/false, please.

export default function ({client, hide}) {
  return <p>Not availabile in current version</p>
}
/*
let mountedOn
export default function ({client, hide}) {
  const [focusNode] = useParam('focusNode')
  const cyDiv = React.useCallback(div => {
    if (div) {
      if (div === mountedOn) {
        console.log('diagram unnecessarily re-mounted')
        return
      }
      mountedOn = div
      if (navigator.userAgent.includes("jsdom")) {
        console.log('skipping diagram mount, no cytoscape on jsdom')
      } else {
        client.graph.ensureMounted(div)
        console.log('diagram mounted')
      }
    } else {
      mountedOn = null
      if (!navigator.userAgent.includes("jsdom")) {
        console.error('diagram unmounted?!?!')
      }
      // client.graph.ensureUnmounted()
    }
  }, [client])

  const cy = client.graph.cy
  cy.nodes('.focus').removeClass('focus')
  if (focusNode) {
    const ele = cy.getElementById(focusNode)
    if (ele && ele.isNode()) {
      ele.addClass('focus')
    } else {
      console.log('focusNode %o not in cy graph', focusNode)
    }
  }
  
  const style = {
    height: '100%',
    // border: '1px solid gray',
    zIndex: 10,
    boxSizing: 'border-box'
  }
  if (hide) style.display = 'none'
  
  return (
    <div ref={cyDiv} style={style}></div>
  )
}
*/
