import React from 'react'
import { Formik, Field, Form } from 'formik'
import { useModals } from './modals'

export function useUser (client) {
  const [user, setUser] = React.useState(client.user)
  React.useEffect(() => {
    const handle = () => setUser(client.user)
    client.on('change-user-data', handle)
    return () => { client.off('change-user-data', handle) }
  }, [client, setUser])
  return user
}

export default function (props) {
  const {client, closeModal} = props
  const user = useUser(client)
  const [message, setMessage] = React.useState('')
  // const firstField = React.useRef(null)
  
  console.log('rendering User, user=%O', user)
  
  if (user) return <UserInfo {...props} user={user}/>

  // if (firstField.current) firstField.current.focus()
  
  const bodyClick = event => {
    console.log('body click')
    event.preventDefault()
  }
  
  return (
    <Formik
      initialValues={{
        email: '',
        cw_pw: '',
        ic: ''
      }}
      onSubmit={async values => {
        console.log('submitting', values)
        try {
          if (values.ic) {
            await client.acceptInvitation(values.ic)
          } else {
            await client.login(values)
          }
          console.log('logged in %O', client.user)
          closeModal()
        } catch (e) {
          if (e.code === 'BAD_AUTH') {
            setMessage('unknown user or incorrect password')
          } else {
            setMessage('Unknown error: ' + JSON.stringify(e))
          }
        }
      }}
    >
      {({ isSubmitting, handleReset }) => (
        <Form id="login-form">

          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Login</p> 
             <button type="button" className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            
            <section className="modal-card-body" onClick={bodyClick}>

              <div className="columns">
                <div className="column" style={{borderRight: '1px solid gray'}}>

                  <p>Use email/password:</p>

                  <div className="field">
                    <label className="label"htmlFor="email">Email</label>
                    <Field
                      tabIndex="0"
                      id="email"
                      name="email"
                      placeholder="jane@acme.com"
                      type="email"
                    />
                  </div>
                  
                  <div className="field">
                    <label className="label" htmlFor="cw_pw">Password</label>
                    <div className="control">
                      <Field tabIndex="0" id="cw_pw" name="cw_pw" placeholder="" type="password" />
                    </div>
                  </div>
                </div>
                <div className="column">
                  Or use:
                  
                  <div className="field">
                    <label className="label" htmlFor="ic">Invitation Code</label>
                    <div className="control">
                      <Field tabIndex="0" id="ic" name="ic" placeholder="" />
                    </div>
                  </div>
                 </div>
              </div>

              <p>
                <b>
                  {message}
                </b>
              </p>
              
            </section>
            <footer className="modal-card-foot">
              <div className="buttons">
                <button type="button" className="button" onClick={closeModal}>Cancel</button>
                <button type="button" className="button" onClick={handleReset}>Reset</button>          
                <button disabled={isSubmitting} type="submit" tabIndex="0" form="login-form" className="button is-primary">Login</button>
              </div>
            </footer>
          </div>
        </Form>
      )}
    </Formik>
  )
}

function UserInfo ({client, user, closeModal, params}) {
  const { openModal } = useModals(params)

  const children = []
  /*
  for (const [key, value] of Object.entries(user)) {
    children.push(<p>{key}: {JSON.stringify(value)}</p>)
  }
  */
  // link over to InvitationsMade, Devices Authorizes, etc
  // change cw_pw
  // change name

  return (
    <div className="modal-card">
      <header className="modal-card-head" onClick={closeModal}>
        <p className="modal-card-title">User Information</p>
        <button type="button" className="delete" aria-label="close"></button>
      </header>
      
      <section className="modal-card-body">
        <button className="button" onClick={() => openModal('invite')}>View and Create Invitations</button>
        {children}
        <p className="mt-5">Password change & Profile editing coming soon</p>
      </section>
      <footer className="modal-card-foot" onClick={closeModal}>
        <div className="buttons">
          <button onClick={closeModal} className="button">OK</button>
          <button onClick={() => client.logout()} className="button is-danger">Log Out</button>
        </div>
      </footer>
    </div>
  )
}


// <button onClick={() => alert('got it')} className="button is-disabled is-success">Save changes</button>
