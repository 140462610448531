import React from 'react';
import 'bulma/css/bulma.css'
// import 'react-bulma-components/dist/react-bulma-components.min.css'
import './more.css'
import { Container, Heading, Section, Hero, Button, Columns } from 'react-bulma-components';
import Navbar from './Navbar'
// import { Switch, Route } from 'react-router-dom'
import { brand } from './branding'
// import { Linq } from './Linq'
import About from './About'
import How from './How'
import Settings from './Settings'
import Nodes from './Nodes'
import Node from './Node'
import Diagram from './Diagram'
import Invite from './Invite'
import { Panelist, Panel } from './layout'
import { Modal } from './modals'
import UseInvitation from './UseInvitation'
import User from './User'
// import { useUser } from './User'
import Search from './Search'

// let renderCount = 0

function App({layout, client, params}) {
  // const user = useUser(client)
  // console.log('App running with user %o', user)

  // this makes render run twice.   WHY?
  React.useEffect(() => {
    layout.apply()
  }, [layout])

  /*
  // user change. maybe not just user data change, but at least
  // login/logout
  React.useEffect(() => {
    client.setRoot(user ? user.nodeid : undefined)
  }, [user, client])
  */

  const spinnerRef = React.useCallback(ele => {
    client.spinner = ele
  }, [client])

  // if (params.data('invitationCode')) openModal('useInvitation')
  if (params.data('invitationCode')) params.data('modals', 'useInvitation')

  const props = { layout, client, params }

  return (
    <div className="App">
      <Navbar  {...props}/>
      
      <Modal {...props} name="how"><How /></Modal>
      <Modal {...props} name="about"><About /></Modal>
      <Modal {...props} name="user"><User {...props}/></Modal>
      <Modal {...props} name="useInvitation"><UseInvitation {...props}/></Modal>
      <Modal {...props} name="invite"><Invite {...props}/></Modal>
      <Modal {...props} name="search"><Search {...props}/></Modal>
      
      <Panelist layout={layout}>
        <Panel show={true} layout={layout} name="Welcome"><Welcome/></Panel>
        <Panel show={false} layout={layout} name="Settings">
          <Settings client={client} />
        </Panel>
        <Panel show={false} layout={layout} name="Nodes">
          <Nodes client={client} layout={layout} name="Nodes"/>
        </Panel>
        <Panel show={false} layout={layout} name="Node">
          <Node client={client} layout={layout} />
        </Panel>
        <Panel show={false} layout={layout} name="Diagram">
          <Diagram client={client} />
        </Panel>
        <Panel show={false} layout={layout} name="Invite">
          <Invite client={client} />
        </Panel>
      </Panelist>
      
      <div ref={spinnerRef} className="mainSpinner" style={{display: 'none'}}>
        <progress className="progress is-small is-primary" max="100">running...</progress>
      </div>
      
      <footer style={{position: "fixed", bottom: 0, left: 0, right: 0, zIndex:10, background: "white"}}>
        <p style={{textAlign: "center"}}>
          <a style={{padding: "0.5rem"}} href="https://trustlamp.canny.io/">Feedback</a>
          |
          <a style={{padding: "0.5rem"}} href="https://hawkeworks.com/">Contact</a>
          |
          <a style={{padding: "0.5rem"}} href="https://hawkeworks.com/privacy">Privacy Policy</a>
          |
          <a style={{padding: "0.5rem"}} href="https://hawkeworks.com/terms">Terms</a>
        </p>
      </footer>
    </div>
  )
}

function MyHero () {
  return (
    <>
      <Section>
        <Hero color="primary" gradient>
          <Hero.Body>
            <Container>
              <Heading size={2}>
                {brand.name}
              </Heading>
              <Heading subtitle size={3}>
                {brand.tagline}
              </Heading>
            </Container>
          </Hero.Body>
        </Hero>
      </Section>
      <Section>
      <article className="message is-dark">
        <div className="message-header">
          <p>Invitation Only (Early Beta)</p>
        </div>
        <div className="message-body">
          <p>
            If you have an invitation link, visit that link.
          </p>
          <p>
            If you have an invitation code, use the Login button, then enter the code.
          </p>
          <p>
            Otherwise, please check back soon.
          </p>
        </div>
    </article>
    </Section>
    </>
  )
}

function Welcome () {
  return (
    <>
      <MyHero />
      {false &&
      <Section>
        <Columns>
          <Columns.Column />
          <Columns.Column>
            <button className="is-medium button">How it works</button>
          </Columns.Column>
          <Columns.Column />
          <Columns.Column>
            <Button size="medium">Explore as guest</Button>
          </Columns.Column>
          <Columns.Column />
          <Columns.Column>
            <Button disabled size="medium" color="info">Login with Twitter (Soon)</Button>
          </Columns.Column>
          <Columns.Column />
        </Columns>
      </Section>
      }
    </>
  )
}

export default App

