const debug = require('debug')('visiparam/yargs')

function addToYargs (params, y) {
  for (const param of params.values()) {
    // console.log('\n\n => %O', vp)

    // https://yargs.js.org/docs/#api-optionkey-opt

    // need to run post-processing too?
    if (param.allowedValues) {
      const names = param.allowedValues.map(x => x.name)
      y = y.option(param.id, {
        describe: param.title + ', one of ' + names,
        type: 'string',
        default: param.default ? param.default.name : undefined
        // allowedValues: names
      })
    }
    
    if (param.min) {
      y = y.option(param.id, {
        describe: param.title + `, in range ${param.min}..${param.max}`,
        type: 'number',
        default: param.default !== undefined ? param.default : undefined
      })
    }
    
  }

  y = y.check(argv => {
    // not really checking, this just gives us a chance to see
    // post-parse argv and store the values into the visiparams.

    // console.log('CHECK RUNNING, argv %O', argv)
    
    for (const param of params.values()) {
      const val = argv[param.id]
      debug(`Setting ${param.id} to %O`, val)
      param.current = val
      debug(`${param.id}.current =  %O`, param.current)
    }

    return true
  })
  
  return y
}

module.exports = { addToYargs }
