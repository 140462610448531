let nav, document
const H = require('escape-html-template-tag')

if (typeof window === 'undefined') {
  nav = {
    state: { },
    init: () => {},
    jump: () => {},
    on: () => {}
  }
  document = {
    simulated: true,
    querySelectorAll: () => [],
    querySelector: () => undefined,
    getElementById: () => { return { style: {} } }
  }
} else {
  nav = require('/home/sandro/Repos/nav-spa')
  document = window.document
}

let seq = 0

function genid (base = "_ugly_") {
  return base + (++seq)
}

module.exports = { genid, nav, document, H }
