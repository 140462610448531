const EventEmitter = require('eventemitter3')
const debug = require('debug')('visiparam/checkbox')
const { nav, document, genid } = require('./common')
const { Param } = require('./param')

class Boolean extends Param {
  defaultDefault () {
    return false
  }
  
  internalize (s) {
    if (typeof s === 'boolean') return s
    return parseBoolean(s)
  }

  get html () {
    const eid = genid()
    const checked = this.current ? ' checked' : ''  // do we need this??
    return `       
       <label for="${eid}">${this.title}:</label>
       <input id="${eid}" type="checkbox"${checked} /> 
`
  }

  bindToDOM (div) {
    const input = div.querySelector('input')
      
    input.addEventListener('change', ev => {
      this.current = ev.target.checked
    })

    this.on('change', () => {
      input.checked = this.current
    })
  }
}

function parseBoolean (t) {
  if (typeof t === 'string') {
    t = t.toLowerCase()
    if (t === 't' || t === 'true' || t === 'y' || t ==='yes' || t === '1') return true
    return false
  }
  return !!t
}

module.exports = { Boolean }
