/*
  Provide the Graph interface, but with a limited number of edges.

  Currently implemented via periodic rebuild, which I think works okay
  for our use case. Maybe add handling events without a rebuild at
  some point.
*/

const { FrontGraph } = require('./front-graph')
const { Graph } = require('./graph')
const delay = require('delay')

class LimitedGraph extends FrontGraph {
  constructor (options) {
    super(options)

    if (this.limit === undefined) this.limit = Number.MAX_SAFE_INTEGER
    if (this.edgeCompare === undefined) throw Error('edgeCompare not provided')
    if (!this.sourcePriority) this.sourcePriority = new Map() // nodeid -> priority

    this.backingStoreChanges = 0
    this.paramChanges = 0

    if (!this.noStart) this.start()
  }

  // need to make changes from backingStores are *NOT* applied to us.  We do
  // that ourselves during rebuild.
  watchBackingStores () {
    for (const backingStore of this.backingStores) {
      // handle setEdgePayload differently:
      const makeDirty = () => this.backingStoreChanges++
      backingStore.on('setEdgePayload', makeDirty)
      this.onClose.push(() => backingStore.off('setEdgePayload', makeDirty))

      for (const eventName of ['setNodePayload']) {
        const handler = (...args) => this[eventName](...args)
        backingStore.on(eventName, handler)
        this.onClose.push(() => backingStore.off(eventName, handler))
      }
    }
  }

  async start () {
    this.debug('loop started')
    let myResolve
    while (!this.closed) {
      this.rebuildDone = new Promise(resolve => { myResolve = resolve })
      // this.debug('loop: pre-delay, this.closed=%o', this.closed)
      await delay(5)
      // this.debug('loop: post-delay')
      if (this.closed) break
      // this.debug('loop: pre-rebuild')
      await this._rebuild()
      // this.debug('loop: post-rebuild')
      myResolve() // note they wont start running until the delay() again
    }
    this.debug('loop terminated')
    if (myResolve) myResolve()
  }

  async rebuild () {
    if (this.rebuildNeeded()) {
      this.debug('someone waiting on rebuild')
      await this.rebuildDone
      this.debug('that person is free now')
    }
  }

  set limit (n) {
    if (typeof n !== 'number') throw Error('bad limit values')
    this._limit = n
    if (this.hardLimit === undefined) {
      // empirically, 1.5 seems to be about right. clearly faster than 1.2 or 1.7.
      this._hardLimit = Math.floor(n * 1.50)
    } else {
      this._hardLimit = this.hardLimit
    }
    if (!this.paramChanges) {
      this.debug('paramChanged++ because set limit')
    }
    this.paramChanges++
    this.debug('limit set to %o', n)
  }
  get limit () { return this._limit }

  setSourcePriority (nodeid, pri) {
    this.debug('setSourcePriority(%o, %o)', nodeid, pri)
    if (pri === undefined) {
      // do we care if we're holding it?
      this.sourcePriority.delete(nodeid)
    } else {
      this.sourcePriority.set(nodeid, pri)
    }
    if (!this.paramChanges) {
      this.debug('paramChanged++ because setSourcePriority')
    }
    this.paramChanges++
  }

  async assembleEdges () {
    this.debug('>assembleEdges()')
    let minEdge = null // can we figure out when it's safe to re-use this?

    const cmp = this.edgeCompare.bind(this)
    const edges = []
    // traverse all the sourcePriority nodes, looking for outgoing edges
    // better than minEdge

    // If the edge is better than minEdge, keep it. But if we're well
    // over the limit, then drop back down to the limit and set a new
    // minEdge. We do this sort+chop a log(n) number of times, and it
    // keeps us from using much more than this.limit memory.
    const maybeSaveEdge = edge => {
      // this.debug('.. maybe save edge %o', edge)
      if (minEdge && this.edgeCompare(edge, minEdge) >= 0) {
        // this.debug('.. .. not saving')
        return
      }
      edges.push(edge)
      // this.debug('.. .. yes')
      if (edges.length > this._hardLimit) {
        // this.debug('.. .. .. over hard limit, sorting')
        edges.sort(cmp)
        // this.debug('.. .. .. sorted to %O', edges)
        minEdge = edges[this.limit - 1]
        // this.debug('.. .. .. limit = %o, set minEdge = %o', this.limit, minEdge)
        edges.splice(this.limit)
        // edges = edges.slice(0, this.limit)  like 5% slower
        // this.debug('.. .. .. spliced to %o elements', edges.length)
      } else {
        // this.debug('.. .. .. under hard limit %o <= %o', edges.length, this._hardLimit)
      }
    }

    for (const backingStore of this.backingStores) {
      // use a heap to do them in priority order?  No, because we need
      // to go through them all in any case. The word "priority" is really
      // referring to how the priority is used in comparing edges.

      for (const [sourceid, sourcePriority] of this.sourcePriority) {
        await backingStore.forEachEdge(sourceid, null, (sourceid2, targetid, edgePayload) => {
          if (sourceid !== sourceid2) throw Error()
          // sneaking in fourth parameter to edge, for speed
          maybeSaveEdge([sourceid, targetid, edgePayload, sourcePriority])
        })
      }
    }

    edges.sort(cmp)
    edges.splice(this.limit)
    this.debug('<assembleEdges(), returning %o edges', edges.length)
    return edges
  }

  rebuildNeeded () {
    return this.backingStoreChanges > 0 || this.paramChanges > 0
  }

  async _rebuild () {
    if (!this.rebuildNeeded()) return
    this.debug('> rebuild() after %o backingStoreChanges, %o paramChanges',
      this.backingStoreChanges, this.paramChanges)
    this.backingStoreChanges = 0
    this.paramChanges = 0

    const edges = await this.assembleEdges()
    const newState = new Graph()
    for (const edge of edges) newState.setEdgePayload(...edge)
    // use [... ] just to make debugging easier for now
    const patch = [...this.computePatchTo(newState)]
    this.debug('actually making the changes at end of rebuild()')

    this.applyPatch(patch) // does all the emits

    // do these matter?   Maybe not.
    // if (this.backingStoreChanges) console.warn('limited-graph: backingStore changed during rebuild')
    // if (this.paramChanges) console.warn('limited-graph: param changed during rebuild')
    this.debug('  patched to %O', this.dump())
    this.debug('< rebuild() x')
  }
}

module.exports = { LimitedGraph }
