const debug = require('debug')('visiparam/pointer')
const { H, nav, document, genid } = require('./common')
const { Param } = require('./param')

class Pointer extends Param {
  defaultDefault () {
    return this._allowedValues[0]
  }

  get allowedValues () {
    return [...this._allowedValues] // make a copy for safety
  }
  
  set allowedValues (n) {
    if (typeof n === 'function') {
      this.allowedValuesFunction = n // re-run by factory on changes
      n = n()
      debug('allowValues set to function which returned %o', n)
    }
    
    const from = this._allowedValues
    this._allowedValues = [...n] // copy for safety
    // the HTML code can (& should) watch this and update the DOM.
    debug('changing allowedValues from', from)
    debug('changing allowedValues to  ', this._allowedValues)

    this.limitsChanged(from) // tell superclass to maybe unclamp; re-internalize

    /*
    let i = this._allowedValues.indexOf(this.current)
    debug('current is at position', i)
    if (this.current) {
      //this.current = this.current.name // re-internalize, in case objects changed
    }
    i = this._allowedValues.indexOf(this.current)
    debug('current is at position', i)
    */
    debug('emitting change')
    this.emit('change-allowedValues', {from, to: this._allowedValues})
  }
  
  internalize (match) {
    if (typeof match === 'string') {
      for (const choice of this._allowedValues) {
        if (choice.name === match) return choice
      }
      for (const choice of this._allowedValues) {
        if (closeMatch(choice.name, match)) return choice
      }
      // fallthrough
    }
    for (const choice of this._allowedValues) {
      if (choice === match) return match
    }
    console.warn(`Pointer ${this.id} value ${JSON.stringify(match)} not in allowedValues, clamping`)
    return undefined // folks gotta check for this
  }

  moveToNext () {
    debug('movetoNext from', this.bestCurrent)
    debug('moveToNext allowedValues:', this._allowedValues)
    this.current = elementAfter(this.bestCurrent, this._allowedValues)
    debug('moveToNext to', this.current)
  }
  
  moveToPrev () {
    this.current = elementBefore(this.bestCurrent, this._allowedValues)
  }

  get htmlOfOptions () {
    if (!this._allowedValues) throw Error('no allowedValues provided')
    if (this._allowedValues.length < 1) throw Error('at least one allowedValue required')

    const out = []
    for (const choice of this._allowedValues) {
      const name = this.externalize(choice)
      out.push(`         <option value="${name}">${name}</option>`)
    }
    return out.join('\n        ')
  }
  
  get html () {
    // The SELECTED item is set after this is added to the // DOM.
    // That's done in bindToDOM()
    return `       <label>${this.title}:</label>
       <button style="padding: 0" class="prev" title="Previous selection">-</button>
       <button style="padding: 0" class="next" title="Next selection">+</button>
       <select title="Select ${this.title}">
${this.htmlOfOptions}
       </select>`
    //   <button style="padding: 0" id="about-${this.id}">?</button>
  }


  bindToDOM (div) {
    const select = div.querySelector('select')
    const prev = div.querySelector('.prev')
    const next = div.querySelector('.next')
    
    div.querySelector('select').addEventListener('change', ev => {
      this.current = ev.target.value
    })
    prev.addEventListener('click', () => this.moveToPrev())
    next.addEventListener('click', () => this.moveToNext())
    
    this.on('change', () => {
      select.value = this.externalize(this.bestCurrent)
    })
    this.on('change-allowedValues', () => {
      select.innerHTML = this.htmlOfOptions
      const newValue = this.externalize(this.bestCurrent)
      debug('selecting value =', newValue)
      select.value = newValue
    })

    select.value = this.externalize(this.bestCurrent)
  }
}

function closeMatch (a, b) {
  return ( typeof b === 'string' &&
           a.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_') ===
           b.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_'))
}

function elementAfter (e, array) {
  debug('elementAfter', e, array)
  let i = array.indexOf(e)
  if (i === -1) console.error('current element no longer in allowed values')
  debug('elementAfter found e at position', i)
  i++
  if (i >= array.length) i = 0
  debug('elementAfter returning', array[i])
  return array[i]
}

function elementBefore (e, array) {
  let i = array.indexOf(e)
  if (i === -1) console.warn('current element no longer in allowed values')
  i--
  if (i < 0) i = array.length - 1
  return array[i]
}

module.exports = { Pointer }
