import React from 'react'
import { params } from './params'
// import { Button } from 'react-bulma-components';
// import { useStateLS } from './useStateLS'

// const sandhawke = '23556190'

export default function ({client}) {
  React.useEffect(() => {
    for (const param of params) {
      param.removeFromDOM() // to avoid dups
      param.addToDOM()
    }
    return () => {
      for (const param of params) {
        param.removeFromDOM()
      }
    }
  })
  return (
    <div>
      <h2>Settings</h2>
      <div className="graph-controls">Graph Settings</div>
      <div className="scoring">Scoring Settings</div>
    </div>
  )
}

// <RootControl client={client}/>

/*
function RootControl ({client}) {
  const [roots, setRoots] = useStateLS('roots', [])
  const [manual, setManual] = React.useState('')
  client.setRoots(...roots)
  
  const addRoot = n => {
    const m = new Set(roots)
    if (n) m.add(n)
    setRoots([...m.values()])
  }
  return (
    <div style={{background: 'rgb(255,228,181)', paddingLeft: '1rem', padding: '0.5rem'}}>
      <p>Not logged in.  Select one or more roots of trust:</p>
      <div>
        <Button size="small" onClick={() => {setRoots([])}}>Clear</Button>
        <Button size="small" onClick={() => {addRoot(sandhawke)}}>@sandhawke</Button>
        <Button size="small" onClick={() => {addRoot('holden')}}>@holden</Button>
        Other:
        <input size={8} value={manual} type="text" onChange={e => {setManual(e.target.value)}} />
        {manual ? <Button size="small" onClick={() => {addRoot(manual); setManual('')}}>Add @{manual}</Button> : ''}
      </div>
      <p>
        Roots currently: <tt>{JSON.stringify(roots)}</tt>.
      </p>
    </div>
  )
}

*/
