const { Graph } = require('./graph')
const { LimitedGraph } = require('./limited-graph')
const { taper } = require('./taper')
const debug = require('debug')('crawling')
const equal = require('fast-deep-equal')
const { computeNodeScores, edgeCompare4 } = require('./score')

/**
   This is a Limited Graph which includes a system for computing a
   priority for target nodes and adding them as source nodes if their
   priority is good enough, so it recursively grows to the limit.

   We use a "mosted trusted rater" scoring system by default, which
   should be good for most cases. To use it, setSourcePriority(root,
   1) (or whatever) and make sure each edge has payload.score
   (0..1). MTR scores are scaled so 50% is neutral.

   I'm not quite sure what should happen to crawled nodes if you lower
   the limit and the supporting edges go away.  Maybe rebuild...?
*/
class CrawledGraph extends LimitedGraph {
  constructor (options) {
    super(options)
    if (!this.taper) this.taper = taper
    this.edgeCompare = edgeCompare4
  }

  /*
  // ? or use on('setEdgePayload', ...updateTargetPriority...)
  setEdgePayload (s, t, p) {
    super.setEdgePayload(s, t, p)
    this.setRecursivePriority(s, t, p)
  }
  */

  /**
     Potentially call setSourcePriority(targetid, x), depending on
     some details about the source and edgePayload, and maybe
     recursively using our outgoing edges.
  */
  /*
  setRecursivePriority (sourceid, targetid, edgePayload) {
    if (!edgePayload) return // it's the edge being deleted
    // debug('> setRecursivePriority(%o, %o, %o)', sourceid, targetid, edgePayload)

    // Is any new priority from a more-trusted-rater than any current
    // rating? If so, we'll figure out this new priority, set it,
    // propagate to any descendents we have. Cycles shouldn't be a
    // problem, because we only propagate when we're changing things
    // toward the better.

    let doChange
    const fromPri = this.sourcePriority.get(sourceid)
    if (!fromPri) return

    const oldPri = this.sourcePriority.get(targetid)
    if (oldPri === undefined) {
      // debug('.. no existing priority')
      doChange = true
    }
    if (!doChange) {
      // debug('.. checking existing priority')
      const oldPriSource = this.usedIncomingFrom.get(targetid)
      if (!oldPriSource) {
        // debug('.. had no parent, so this must be a manually set, leaving it')
        return
      }
      const oldFromPri = this.sourcePriority.get(oldPriSource)
      if (oldFromPri === 'undefined') throw Error('internal error')
      if (fromPri > oldFromPri) {
        // debug('.. this is a more-trusted-rating')
        doChange = true
      } else {
        // debug('.. less trusted, skip it, %o', { fromPri, oldPriSource, oldFromPri })
      }
    }

    if (doChange) {
      const pri = this.taper(fromPri, edgePayload.score)
      // debug('.. pri = %o, taper(%o, %o)', pri, oldPri, edgePayload.score)
      this.setSourcePriority(targetid, pri)
      this.usedIncomingFrom.set(targetid, sourceid)

      const target = this.loadedNodes.get(targetid)
      if (target && target.outs) {
        for (const [childid, childEdgePayload] of target.outs) {
          this.setRecursivePriority(targetid, childid, childEdgePayload)
        }
      }
    }
    // debug('< setRecursivePriority')
  }
*/

  /*
  // switch to using taper??
  targetPriority (sourceid, targetid, edgePayload) {
    let sourceScore = this.sourcePriority.get(sourceid)
    if (sourceScore === 1) return 1 // prioritize manually added
    let edgeScore = (edgePayload && edgePayload.score)
    if (!sourceScore) sourceScore = 0
    if (!edgeScore) edgeScore = 0
    const edgeConfidence = 0.5 + Math.abs(edgeScore - 0.5)
    return sourceScore * edgeConfidence
  }
  */

  edgeCompare () {
    throw Error('overriden on a per-instance basis')
  }
  
  // this is the speed-critical code
  XXedgeCompare (a, b) {
    // edges are [sourceid, targetid, edgePaylod, sourcePriority]

    // sort by sourcePriority
    // then edgePayload.socre
    // then sourceid  (just so order is deterministic)
    // then targetid  (just so order is deterministic)

    let c = b[3] - a[3]

    if (c === 0) {
      c = b[2].score - a[2].score

      if (c === 0) {
        if (typeof b[0] === 'number') {
          c = b[0] - a[0]
        } else {
          c = a[0].localeCompare(b[0], 'en')
        }
        
        if (c === 0) {
          if (typeof b[1] === 'number') {
            c = b[1] - a[1]
          } else {
            c = a[1].localeCompare(b[1], 'en')
          }
        }
      }
    }
    
    return c
  }

  /*
  async _rebuild () {
    /*
      super._rebuild() returns immediately if nothing
      has changed, so okay to call it a lot.  But still
      let's set a limit on total max depth of the crawl?
    * /

    // we don''t want to clear our structures unless super._rebuild()
    // is definitely going to run
    const roots = [...this.roots]
    if (!equal(roots, this.rootsAtLastRebuild)) {
      debug('roots changed to %o', roots)
      this.paramChanges++
      this.rootsAtLastRebuild = roots
    }

    if (this.inputChanges === 0 && this.paramChanges === 0) {
      // this.debug('< no changes')
      return
    }

    this.usedIncomingFrom = new Map()
    this.sourcePriority = new Map()
    this.loadedNodes = new Map()

    // Oh...  If we are really wiping everything, it's silly to run
    // the scoring on edges being added -- we could just run it on
    // best-first traversal of the graph.  That would be simpler.
    //
    // But that code works elegantly, I think, until the limit changes,
    // so let's keep it for now, and maybe limited-graph will start
    // to be smarter soon, without needing rebuilds??

    for (const r of this.roots) this.sourcePriority.set(r, 1)

    for (let i = 0; i < 100; i++) {
      await super._rebuild()

      this.inputChanges = 0  // right now we're conflating edge changes
      // due to input.loadOuts with actual input changes.  ouch.
    }
  }
  */

  // override LimitedGraph on this, wrapping it
  async assembleEdges () {
    debug('\n\n\nassembleEdges')
    let prevEdges
    const history = [] // just for debugging
    if (this.roots.length === 0) return []
    let scores = await computeNodeScores(this.roots, [])

    for (let i = 0; ; i++) {
      if (i > 10) {
        // we need a good way to snapshot large error data structures like this
        console.error('Crawler hit iteration limit %o.  History of edges:', i)
        for (const h of history) {
          console.error('\n--- history frame ---')
          for (const e of h) {
            console.error('%o', e)
          }
        }
        console.error('%j', history)
        throw Error('too many crawl iterations: ' + i)
      }
      this.sourcePriority = scores
      const edges = await super.assembleEdges()
      debug('super iteration %o, gave edges: %O', i, edges)
      if (equal(edges, prevEdges)) return edges
      // const eSorted = [...edges]
      // eSorted.sort(this.edgeCompare)
      // if (!equal(edges, eSorted)) throw Error('edges not in order')
      // console.log('crawling outward %o', i)
      history.push(edges)
      prevEdges = edges
      scores = await computeNodeScores(this.roots, edges)
    }
  }
}

module.exports = { CrawledGraph }
