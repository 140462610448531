const EventEmitter = require('eventemitter3')

class InfiniteTree extends EventEmitter {
  /*
    async loadNode (id) {
      let node = this.loadedNodes.get(id)
      if (node) return node
      return this.setNodePayload(id, true)
    }

    async loadOuts (id) {
      const node = this.loadedNodes.get(id)
      // if (node && node.outs) return node.outs
      // give outselves a parameter to control these?
      this.setEdgePayload(node, id + '_a', { score: 0.9 })
      this.setEdgePayload(node, id + '_b', { score: 0.8 })
      this.setEdgePayload(node, id + '_c', { score: 0.7 })
      this.setEdgePayload(node, id + '_d', { score: 0.6 })
      this.setEdgePayload(node, id + '_e', { score: 0.5 })
      this.setEdgePayload(node, id + '_z', { score: 0.1 })

      return node.outs
    }
    }*/
  async forEachEdge (s, options, callback) {
    if (s.id) s = s.id
    if (typeof s === 'number') {
      callback(s, s * 10 + 9, { score: 0.9 })
      callback(s, s * 10 + 8, { score: 0.8 })
      callback(s, s * 10 + 7, { score: 0.7 })
      callback(s, s * 10 + 6, { score: 0.6 })
      callback(s, s * 10 + 5, { score: 0.5 })
      callback(s, s * 10 + 1, { score: 0.1 })
    } else {
      callback(s, s + '_a', { score: 0.9 })
      callback(s, s + '_b', { score: 0.8 })
      callback(s, s + '_c', { score: 0.7 })
      callback(s, s + '_d', { score: 0.6 })
      callback(s, s + '_e', { score: 0.5 })
      callback(s, s + '_z', { score: 0.1 })
    }
    return true
  }
  close () {}
}

module.exports = { InfiniteTree }
