import React from 'react'
import visiparam from 'visiparam'

function useParam (name) {
  const [value, setValue] = React.useState(visiparam.data(name))

  React.useEffect(() => {
    const handler = param => {
      if (param.label === name || param.id === name) {
        console.log('param %o changed to %o', name, param.current)
        setValue(param.current)
      }
    }
    visiparam.on('change', handler)
    return () => { visiparam.off('change', handler) }
  }, [setValue, name])

  const wrappedSetValue = newValue => {
    visiparam.data(name, newValue)
  }
  
  return [value, wrappedSetValue]
}

export { useParam, visiparam as params }
