import React from 'react'
import { Heading, Section } from 'react-bulma-components'
import { brand } from './branding'
import { ModalCard } from './modals'

export default function About ({params}) {
  // const { openModal } = useModals(params)
  const mailto = `mailto:sandro@hawke.org?subject=feedback on ${brand.site}`

  /*
  const pStyle = {
    marginTop: '1rem'
  }
  */

  return (
    <ModalCard title={'About ' + brand.name}>
    <Section>

      <Section>
        <Heading size={5}>Warning</Heading>
        <p>This site is under development. <b>Do not trust this site with any important data. It probably has serious bugs.</b> Please send feedback to <a className="email-link" href={mailto}>sandro@hawke.org</a>. <b style={{color: 'red'}}>Use at your own risk.</b></p>

        <p className="mt-4">Additional terms and disclaimers available using links at the bottom of the page.</p>
      </Section>

    </Section>
    </ModalCard>
  )
}

/*
      <Section>
        <Heading size={5}>Peers</Heading>
        
        <p style={pStyle}>This site has several peer sites, running identical software and using the same data. For now, they only differ trivially in branding. It should be painless to switch among them. Give it a try!</p>
        <Brands/>
      </Section>

function Brands () {
  const q = window.location.search
  return (
    <div style={{margin: '1rem'}}>
      <ol>
      {[...Object.entries(brands)].map(([key, value]) => {
        if (value.isDev) return []
        // not using Linq because it doesn't go to other sites ?
        return (
          <li key={value.site}><a href={value.site + q}>{value.name} &mdash; {value.tagline}</a></li>
        )
      })}
      </ol>
    </div>
  )
}

*/
export { About }
