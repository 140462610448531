const { EventEmitter } = require('eventemitter3')
// const { Graph, pct } = require('/home/sandro/Repos/osca-core')
const { Graph: GiantGraph,
        computeNodeScores
      } = require('/home/sandro/Repos/giant-graph')
const rws = require('reconnecting-websocket')
const equals = require('equals')
const equal = require('fast-deep-equal')
const debug = require('debug')('osca-client')
const customError = require('custom-error')
const visiparam = require('/home/sandro/Repos/visiparam')
const delay = require('delay')

// console.error('client init running') 
visiparam.number('Max Edges', { class: 'graph-controls', min: 0, max: 10000, default: 5 })
// visiparam.number('Max Nodes', { class: 'graph-controls', min: 0, max: 10000, default: 4 })
// visiparam.number('Node Min Hi', { class: 'graph-controls', min: 50, max: 100, default: 90 })
// console.log('visiparam._items: ', visiparam._items)

const TimeoutError = customError('TimeoutError')

// allow this package to be used from es6modules and node modules
const ReconnectingWebSocket = rws.default ? rws.default : rws
// console.log('RWS = %O %O', rws, ReconnectingWebSocket)

let counter = 0

// confidence is measured on a scale from 0.5 to 1.0 for ... reasons
const confidence = score => score ? (0.5 + Math.abs(score - 0.5)) : 0.5

class Client extends EventEmitter {
  constructor (options = {}) {
    super()
    debug('Client() #%o constructed', ++counter)

    // debugging
    window.client = this

    // or you can set it later
    this.spinner = options.spinner
    this.showBusy()

    this.gg = new GiantGraph()
    this.maintainNodeScores(this.gg) // hack, no way to cancel
    this.showProgress(this.gg) // hack, no way to cancel

    // we don't even use uses yet:
    // this.nodes = new Map() // id => payload
    // this.edges = new ArcMap() // sourceid => targetid => payload

    this.messageHandlers = {
      // 'RPC-response': this.onResponse.bind(this),
      // 'update': this.onUpdate.bind(this),
      // 'node-data': this.onNodeData.bind(this),
      // 'edge-data': this.onEdgeData.bind(this),
      'user-data': this.onUserData.bind(this),
      'message-to-user': this.onMessageToUser.bind(this),
      'invitation-created': () => {},
      // 'set-node': this.onSetNode.bind(this),
      // 'delete-node': this.onDeleteNode.bind(this),
      // 'set-edge': this.onSetEdge.bind(this),
      // 'delete-edge': this.onDeleteEdge.bind(this)
    }

    this.onOpen = this.onOpen.bind(this)
    this.onMessage = this.onMessage.bind(this)
    // this.graph = new Graph() // WARNING - visiparams release a dangerous clone
    this.url = options.url || 'ws://localhost:8081'
    // console.log('Using server at %o', this.url)
    this.ws = new ReconnectingWebSocket(this.url, [], {
      connectionTimeout: 10000,
      debug: false
    })
    this.ws.addEventListener('open', this.onOpen)
    this.ws.addEventListener('close', () => { this.onReset(); debug('ws close') })
    this.ws.addEventListener('message', this.onMessage)
    this.ws.addEventListener('error', e => { this.onReset(); debug('ws error', e) })
    this.loading = true
    // this.nodeMaxLow = 0.40
    // this.dirty = 0
    this.onReset()
    // this.pings = new Map()
    // this.pingCounter = 0
    // this.roots = []
    this.outstandingRPCs = new Map()
    this.rpcCounter = 1
    this.visiparam = options.visiparam || visiparam
    // this.nodeMinHi = 0.60
    // this.minConfidence = 0.55

    this.paramChanges = 0
    this.visiparam.id.maxEdges.on('change', n => { this.limit = n })
    this.start()
  }
  async close () {
    this.isOpen = false
    this.ws.close()
  }

  // Begin copy from giant-graph/limited-graph
  //
  // Should probably be moved to FrontGraph so we can use it.
  //
  // For now, just copying...

  async start () {
    debug('loop started')
    let myResolve
    while (!this.closed) {
      this.rebuildDone = new Promise(resolve => { myResolve = resolve })
      // debug('loop: pre-delay, this.closed=%o', this.closed)
      await delay(5)
      // debug('loop: post-delay')
      if (this.closed) break
      // debug('loop: pre-rebuild')
      await this._rebuild()
      // debug('loop: post-rebuild')
      myResolve() // note they wont start running until the delay() again
    }
    debug('loop terminated')
    if (myResolve) myResolve()
  }

  async rebuild () {
    if (this.rebuildNeeded()) {
      debug('someone waiting on rebuild')
      await this.rebuildDone
      debug('that person is free now')
    }
  }

  set limit (n) {
    if (typeof n !== 'number') throw Error('bad limit values')
    this._limit = n
    if (this.hardLimit === undefined) {
      // empirically, 1.5 seems to be about right. clearly faster than 1.2 or 1.7.
      this._hardLimit = Math.floor(n * 1.50)
    } else {
      this._hardLimit = this.hardLimit
    }
    if (!this.paramChanges) {
      debug('paramChanged++ because set limit')
    }
    this.paramChanges++
    debug('limit set to %o', n)
  }
  get limit () { return this._limit }

  rebuildNeeded () {
    return this.paramChanges > 0
  }

  async _rebuild () {
    if (!this.rebuildNeeded()) return
    this.paramChanges = 0

    if (this.isOpen) {
      await this.rpc('setCrawlGraph', {
        // root: this.gg.roots,
        limit: this.limit
      })
    }
  }

  //
  // End copy / modify
  //
  
  send (...args) {
    this.ws.send(JSON.stringify(args))
  }
  rpc (...args) { // optional options, rpc function name, ...rpc params
    let options = {}
    if (typeof args[0] !== 'string') options = args.shift()
    const op = args.shift()
    const params = args

    const seq = this.rpcCounter++
    const clear = () => this.outstandingRPCs.delete(seq)
    const rec = { seq, clear }
    rec.promise = new Promise((resolve, reject) => {
      rec.resolve = resolve
      rec.reject = reject
    })
    if (options.timeout) {
      rec.timeoutHandle = setTimeout(() => {
        const msg = `RPC call time out, op=${op} url=${this.url}`
        console.error(msg)
        rec.reject(TimeoutError(msg))
        clear()
      }, options.timeout)
    }
    this.outstandingRPCs.set(seq, rec)
    this.send(seq, op, ...params)
    this.showBusy()

    return rec.promise
  }
  showBusy () {
    if (!this.spinner) return
    if (this.computing || this.outstandingRPCs.size > 0) {
      this.spinner.style.display = 'block'
      // console.log('spin? computing = %o, size = %o', this.computing, this.outstandingRPCs.size, 'spinning')
    } else {
      this.spinner.style.display = 'none'
      // console.log('spin? computing = %o, size = %o', this.computing, this.outstandingRPCs.size, 'stop')
    }
  }
  onResponse (seq, err, data) {
    const rec = this.outstandingRPCs.get(seq)
    if (!rec) {
      console.warn('server sent extra RPC response, seq =', seq)
      return
    }

    this.outstandingRPCs.delete(seq)
    this.showBusy()
    
    rec.clear()
    if (err) {
      rec.reject(err)
    } else {
      rec.resolve(data)
    }
  }

  // crude, but probably fine. Only needed for testing right now anyway.
  async RPCsDone () {
    while (true) {
      if (this.outstandingRPCs.size === 0) return
      await delay(1)
    }
  }
  
  onReset () {
    // this.watchingNodes = new Set()
    if (this.timerId) {
      clearInterval(this.timerId)
      delete this.timerId
    }
  }
  onOpen () {
    debug('ws open')
    this.isOpen = true

    this.gg.silentClear()
    this.gg.emit('clear') // we don't want to be notified about each change

    this.resumeSession() // don't need to wait for response

    /*

    // crude debouncer, for now, so we don't re-score & re-layout
    // after each little change we get.
    this.timerId = setInterval(() => {
      if (this.dirty) {
        debug('db dirty %o', this.dirty)
        this.dirty = 0
        this.processChanges()
      }
    }, 100)
    if (this.timerId.unref) this.timerId.unref()

    */

    // I don't think we need this -- the server will just send stuff.
    // this.paramChanges++
             
    // this.rpc('setMaxEdges', this.visiparam.data('maxEdges'))
    // this.watchBestNodes() // maybe put this in reset?  queue them up then?
  }
  /*
  watchBestNodes () {
    // sort them by confidence & cutoff an some visiparam limit?
    
    // actually we watch all of them, but we only ask for arcs
    // if we're okay with there being more
    // this.maxNodes = visiparam.data('maxNodes')
    // this.nodeMinHi = visiparam.data('nodeMinHi') / 100
    // console.log('watchBestNodes() %O', {maxNodes: this.maxNodes, nodeMinHi: this.nodeMinHi})

    /*
    let counter = 0, wantEdges = true
    for (const node of this.graph.bestNodes({cutoff: 0, limit: 1000})) {
      if (counter++ > this.maxNodes) wantEdges = false
      // console.log('watching node: %O', node.json())
      this.watchNode(node, wantEdges)
    }
    * /

    for (const node of this.graph.cy.nodes()) this.watchNode(node, true)
  }
  async watchNode (node, wantEdges) {
    node = this.graph.obtainNode(node)
    const nodeid = node.id()
    const nodeScore = node.data('simpleScore')
    debug('watchNode %o, ss %o', nodeid, nodeScore)

    if (confidence(nodeScore) < this.minConfidence) {
      debug('.. too low confidence to watch %O', node.json())
      node.data('tooLowToAskServer', true)
      return node
    }
    node.data('tooLowToAskServer', false)

    const now = {nodeScore}
    const was = node.data('watching')
    if (equals(was, now)) {
      debug('.. was already watching, same params')
      return node
    }
    node.data('watching', now)
    
    const params = { nodeid, priority: nodeScore }
    debug('+watchNode %o rpc', params)
    await this.rpc('setSourcePriority', params)
    debug('-watchNode %o rpc', params)
    return node
  }
  onUpdate (items) {
    debug('onUpdate with %o items', items.length)
    for (const item of items) {
      let ele
      if (item.id) {
        ele = this.onNodeData(item.id, item)
      } else if (item.source) {
        // ele = this.onEdgeData(item.source, item.target, item)
      } else {
        console.log('bad update, all items = %O', items)
        console.log('bad update, bad item = %O', item)
        throw Error('bad update from server')
      }
      ele.data('hasDataFromServer', true)
      debug('.. updated %o %o', ele.group(), ele.id())
    }
    this.dirty++
  }
  */
  onMessage (m) {
    // console.log('ws message %O', m)
    const [op, ...args] = JSON.parse(m.data)

    if (typeof op === 'number') {
      if (op >= 0) {
        if (op > 0) console.error('client doesnt implement returns')
        const method = args.shift()
        const methodName = 'exposed_' + method
        if (typeof this[methodName] === 'function') {
          this[methodName](...args)
        } else {
          console.error('unimplemented RPC method from server: %o', method)
        }
      } else {
        this.onResponse(Math.abs(op), args[0], args[1])
      }
    } else {
      const h = this.messageHandlers[op]
      if (h) {
        // debug('calling h with args %O', args)
        // console.log('calling %o with args %O', h, args)
        h(...args)
      } else {
        console.error('unknown op code from server: %o', op)
      }
      if (op === 'node-data' || op === 'edge-data') this.dirty++
    }
  }
  /*
  onNodeData (id, data) {
    // debug('onNodeData got %o %O', id, data)

    const create = this.graph.cy.nodes().size() < this.maxNodes
    
    const node = this.graph.obtainNode(id, create)
    if (!node) return null
    
    for (const [key, value] of Object.entries(data)) {
      node.data(key, value)
    }
    // maybe set some kind of dirty class on the node, that it needs
    // layout and scoring, when others might not, so we can be more
    // incremental?
    return node // sometimes called without event
  }
  /*
  onEdgeData (sourceid, targetid, data) {
    const source = this.graph.obtainNode(sourceid)
    const target = this.graph.obtainNode(targetid)
    const edge = this.graph.setEdge(source, target, data.score, data.prov)

    const oldSS = target.data('simpleScore')
    const sourceSS = source.data('simpleScore') || 0
    const edgeScore = data.score || 0
    const newSS = sourceSS * edgeScore
    if (confidence(newSS) > confidence(oldSS)) {
      target.data('simpleScore', newSS)
    }
    this.watchNode(target) // does its own filtering
    
    // -- these make debugging hard
    // delete data.score
    // delete data.prov
    for (const [key, value] of Object.entries(data)) {
      if (key === 'score') continue
      if (key === 'prov') continue
      edge.data(key, value)
    }
    return edge
  }
  * /
  processChanges () {
    debug('processChanges() running, ******* NOT *********scoring')
    
    // this.graph.updateNodeScores()

    const cy = this.graph.cy
    for (const n of cy.nodes()) {
      /*
      let name = n.data('msg')
      const tw = n.data('twitterAccount')
      if (tw) {
        name = tw.screen_name + ' ' + pct(n.data('score'))
      }
      * /
      if (n.data('screenName')) {
        n.data('msg', n.data('screenName') + ' ' + pct(n.data('score')))
      }
    }
    this.emit('change')
    debug('processChanges emit change', cy.nodes().size(), cy.edges().size())
    this.graph.runLayout() // maybe just a 'minor' layout??
    // this.watchBestNodes()
  }
  */

  /**
     returns promised of { elapsed }, the time taken in ms

     Maybe it'll include other server info as well?

     add a timeout?  how could this fail without a connection error or
     a server bug?
  */
  async ping (param) {
    const d = {}
    d.start = Date.now()
    d.fromServer = await this.rpc('ping', param)
    d.end = Date.now()
    d.elapsed = d.end - d.start
    return d
  }

  setRoot (root) {
    console.error('best not call setRoot - the server uses the login')
    if (this.gg.setRoot(root)) this.paramsChanged()
  }
  
  // deprecated:
  setRoots (...roots) {
    this.setRoot(roots)
  }

  /*
  elements (options) {
    if (this.graph && this.roots.length) {
      // console.log('elements: options = %o', options)
      const nodes = this.graph.bestNodes(options)
      console.log('db.elements returning best %o nodes', nodes.length)
      return nodes
    } else {
      return []
    }
  }
  nodeData (id) {
    const node = this.graph.cy.getElementById(id)
    // console.log('db.nodeData(%o) returning %O', id, node && node.json())
    return node && node.json()
  }
  */

  onUserData (user) {
    if (user) {
      this.user = Object.assign({}, user)
    } else {
      this.user = null
    }
    this.emit('change-user-data', this.user)
    if (user !== this.wasUser) {
      if (user && !this.wasUser) this.emit('login', user)
      if (!user && this.wasUser) this.emit('logout', this.wasUser)
      this.wasUser = user
    }
  }
  
  onMessageToUser (html) {
    // what's our preferred toast library?    do we .emit this or what?
    console.error('MESSAGE-TO-USER', html)
  }

  async signup (userData) {
    this.user = await this.rpc('signup', userData)
    if (this.user) window.localStorage.sessionId = this.user.id
    // emits are from onUserData, which should have fired during rpc
    // this.emit('change-user-data', this.user)
    // this.emit('login', this.user)
    return this.user
  }
  async login (userData) {
    // let onUserData actually handle setting this.use
    const user = await this.rpc('login', userData)
    console.log('login returned %O', user)
    if (user) {
      window.localStorage.sessionId = this.user.id
      window.localStorage.sessionToken = this.user.token
    }
    // this.emit('change-user-data', this.user)
    // this.emit('login', this.user)
    return user
  }
  async logout () {
    if (this.user) {
      delete window.localStorage.sessionId
      delete window.localStorage.sessionToken
    }
    this.user = null
    await this.rpc('logout')
    // this.emit('change-user-data', this.user)
    // this.emit('logout')
  }
  async setUserData (userData) {
    if (!userData) userData = this.user
    await this.rpc('setUserData', userData)
    // doesn't return anything, because it comes back to all
    // connections as a user data change. This is an RPC so
    // that we can get an error report if there is one, and
    // know when it's expected to have been completed.

    // THIS should come back via the net; we don't need to do it.
    //this.emit('change-user-data', this.user)
  }

  async findInvitation (text) {
    return await this.rpc('findInvitation', text)
  }
  async createInvitation (aboutInvited) {
    // With the current implementation, we could do this locally, but
    // let's leave it to the server to potentially change the format.
    const siteurl = document.location.origin + document.location.pathname
    return await this.rpc('createInvitation', {aboutInvited, siteurl})
    // result also shows up as a change to the list of users we've invited
  }
  async acceptInvitation (text) {
    return await this.login({invitationCode: text})
  }
  // returns a promise of the next user data we receive
  nextUserData () {
    return new Promise(resolve => {
      this.once('change-user-data', u => {
        debug('nextUserData resolving with', u)
        resolve(u)
      })
    })
  }
  async resumeSession () {
    const id = window.localStorage.sessionId
    const token = window.localStorage.sessionToken
    debug('resumeSessions %o', {id, token})
    console.log('resumeSessions %o', {id, token})
    // console.log('localstorage: %O', window.localStorage)
    if (token && id !== undefined) {
      debug('... trying to resume')
      console.log('Trying to resume session with id %o', id)
      try {
        const user = await this.login({id, token})
        console.log('resumed session for user: %o', user.id)
      } catch (e) {
        console.log('resume failed: %o', e)
      }
    }
  }
  syncSearch (text) {
    text = text.toLowerCase()
    const res = new Set()
    for (const node of this.gg.loadedNodes.values()) {
      // console.log('candidate node: %O', node)
      // console.log('... json: %O', node.json())
      for (const field of ['profileAt', 'screenName']) {
        const value = node.data(field)
        if (value && value.toLowerCase().indexOf(text) >= 0) {
          res.add(node)
          break
        }
      }
      if (res.size > 40) break
    }
    return [...res.values()]
  }
  async asyncSearch (prev, text) {
    const res = new Set(prev)
    const fromServer = await this.rpc('nodeSearch', text)

    // console.log('nodeSearch from server %O', fromServer)
    // do we add these to the graph...?  if not, we can't return cy nodes
    //
    // do we at least add the focus?
    //
    // I guess add them for now...   maybe with a class?

    if (fromServer) {
      for (const obj of fromServer) {
        // const was = this.gg.obtainNode(obj.id)
        // const node = this.onNodeData(obj.id, obj) // might as well update
        // res.add(node)
        // if (!was) node.addClass('searchResult') // so we can remove?

        const node = this.gg.obtainNode(obj.id, obj)
        res.add(node)
      }
    }
    return [...res.values()]
  }

  getUserEdge (target) {
    if (!this.user.edges) this.user.edges = []

    for (const edge of this.user.edges) {
      if (edge.target === target) return edge
    }
    return null
  }
  
  async setUserEdge (edge) {
    debug('setUserEdge %o', edge)
    let curr = this.getUserEdge(edge.target)
    if (!curr) curr = {}
    
    Object.assign(curr, edge)
    // console.log('setUserEdge 2 %o', curr)

    this.user.edges = this.user.edges.filter(e => e.target !== edge.target)

    // clean up any bad edges?
    // this.user.edges = this.user.edges.filter(e => e.score || e.prov || e.reason || e.request )
    if (!edge.delete) this.user.edges.push(curr)

    debug('setUserEdge after modification edges=%O', this.user.edges)
    await this.setUserData()
    // should get change events from server in response
  }

  /*
  exposed_setNode (id, payload) {
    debug('setNode', id, payload)
    this.onNodeData(id, payload)
    this.emit('set-node', id, payload)
    this.nodes.set(id, payload)
    this.dirty++
  }
  exposed_deleteNode (id) {
    debug('deleteNode', id)
    this.emit('delete-node', id)
    console.log('ignoring delete-node')
    this.nodes.delete(id)
  }

  exposed_setEdge (sourceid, targetid, payload) {
    debug('setEdge')
    // this is going to create a ton of target nodes in cy which we don't know
    // anything about, and if the edge goes away, we need to make them go away
    // somehow...
    
    this.emit('set-edge', sourceid, targetid, payload)

    const source = this.graph.obtainNode(sourceid)
    const target = this.graph.obtainNode(targetid)
    const edge = this.graph.setEdge(source, target, payload.score, payload.prov)

    const oldSS = target.data('simpleScore')
    const sourceSS = source.data('simpleScore') || 0
    const edgeScore = payload.score || 0
    const newSS = sourceSS * edgeScore
    if (confidence(newSS) > confidence(oldSS)) {
      target.data('simpleScore', newSS)
    }
    this.watchNode(target) // does its own filtering
    
    // -- these make debugging hard
    // delete data.score
    // delete data.prov
    for (const [key, value] of Object.entries(payload)) {
      if (key === 'score') continue
      if (key === 'prov') continue
      edge.data(key, value)
    }
    this.dirty++
    // console.log('set edge %o %o %o', sourceid, targetid, payload)
  }
  exposed_deleteEdge (sourceid, targetid, payload) {
    this.emit('delete-edge', sourceid, targetid, payload)
    this.graph.setEdge(sourceid, targetid, 'none')
    // console.log('deleted edge %o %o', sourceid, targetid)
    this.dirty++
  }

  */

  exposed_e (sourceid, targetid, edgePayload) {
    debug('got edge %o %o %o', sourceid, targetid, edgePayload)

    // The server sometimes sends edges before the node payload is
    // asynchronously available. Hopefully a node will only be in that
    // state for a very short time. Like, the users's shouldn't be
    // seeing nodes like this, with no screenName, etc.
    if (!this.gg.loadedNodes.has(sourceid)) {
      sourceid = this.gg.obtainNode(sourceid, { placeholderPayload: true })
      // throw Error('server sent edge without sending source node first, id = ' + sourceid)
    }
    if (!this.gg.loadedNodes.has(targetid)) {
      targetid = this.gg.obtainNode(targetid, { placeholderPayload: true })
      // throw Error('server sent edge without sending target node first, id = ' + targetid)
    }
      
    this.gg.setEdgePayload(sourceid, targetid, edgePayload)
  }

  exposed_n (nodeid, payload) {
    debug('got node %o %o', nodeid, payload) 
    this.gg.setNodePayload(nodeid, payload)
  }

  async maintainNodeScores() {
    let dirty = 0
    this.gg.on('setEdgePayload', () => { dirty++ })
    this.gg.on('setNodePayload', () => { dirty++ })
    while (true) {
      if (this.user && dirty) {
        dirty = 0
        console.log('recomputing scores')
        this.computing = true
        this.showBusy()
        await delay(1) // give it time to show, since we're compute-bound

        // oh, also, if we got dirty in that 1ms, let's restart, because
        // there's no point in computing while stuff is flowing in
        if (dirty) continue
        
        this.gg.setRoot(this.user.nodeid)
        const t0 = Date.now()
        console.log('----- computing, dirty = %o', dirty)
        const scores = await computeNodeScores(this.gg)
        console.log('----- compute DONE, dirty = %o', dirty)
        // let extraNodes = new Set(this.gg.loadedNodes.values())
        for (const node of this.gg.nodes()) delete node.payload.score
        debug('new scores %O, %oms', scores, Date.now() - t0)
        for (const [id, score] of scores) {
          /*
          const payload = await this.gg.getNodePayload(id)
          const pt = typeof payload
          debug('id=%o score=%o pt=%o, payload=%o', id, score, pt, payload)
          if (pt !== 'object') {
            console.error('wrong payload type (' + pt + ') node ' + id)
          }
          payload.score = score
          */
          const node = this.gg.obtainNode(id, {})
          // extraNodes.delete(node)
          node.payload.score = score  // or node.score ?!
        }

        /* We used to remove unreachable nodes, but the server doesn't
         * send them again since it thinks we still have them, and we
         * probably SHOULD hold on to them in case they become
         * reachable again */
        // for (const node of extraNodes) this.gg.setNodePayload(node, false)

        this.gg.deleteEdgesToMissingTargets()
        this.gg.setIns()
        console.log('scoring done after %oms', Date.now() - t0)
        console.log('scored nodes =  %O', [...this.gg.nodes()])
        this.emit('change')
        console.log('change pushed after total %oms', Date.now() - t0)
        this.computing = false
        this.showBusy()
      }
      await delay(10)
    }
  }
  async showProgress () {
    let c = 0
    while (true) {
      const n = this.gg.edgeCount()
      if (n !== c) console.log('%o edges added', n - c)
      c = n
      await delay(250)
    }
  }
}

// need to know who to connect to....
//
// const client = new Client()

module.exports = { Client } 
