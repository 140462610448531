const { Graph } = require('./graph')

class FrontGraph extends Graph {
  constructor (options) {
    super(options)
    if (this.inputs) throw Error('options.inputs renamed to backingStores')
    if (!this.backingStores) throw Error('FrontGraph needs backingStores')
    this.watchBackingStores()
  }
  
  watchBackingStores () {
    for (const backingStore of this.backingStores) {
      for (const eventName of ['setNodePayload', 'setEdgePayload']) {
        const handler = (...args) => this[eventName](...args)
        backingStore.on(eventName, handler)
        this.onClose.push(() => backingStore.off(eventName, handler))
      }
    }
  }
  
  /**
   * Asynchronously load the payload for this node from the backing
   * store, creating the Node if necessary. Subclasses typically
   * override this based on their notion of backing store.  Default
   * implementation tries each of the backingStores.
   *
   * @param {string} nodeid The id of the node to load
   *
   * @return {(Node|null)} The Node object, or null if none available
   * @public
   */
  async loadNode (nodeid, options) {
    let node = this.loadedNodes.get(nodeid)
    if (node && node.payload !== 'flag that payload is missing') return node

    for (const backingStore of this.backingStores) {
      const payload = await backingStore.getNodePayload(nodeid, options)
      if (payload) {
        node = this.setNodePayload(nodeid, payload)
        if (node) return node
      }
    }
    return null
  }

  async loadOuts (nodeid, options) {
    let node = this.loadedNodes.get(nodeid)
    if (!node) node = await this.loadNode(nodeid, options)
    if (!node) return null // there is no such node

    if (!node.outsLoaded) {
      for (const backingStore of this.backingStores) {
        const outs = await backingStore.loadOuts(nodeid, options)
        if (outs) {
          node.outsLoaded = true
          // merge them in, in case some have been added by the app or
          // by events. Possible timing issue with overwriting them.
          // At some point, consider timestamps or something in cases
          // where there is a conflict?
          for (const [targetid, edgePayload] of outs) {
            node.outs.set(targetid, edgePayload)
          }
          // ?break?  Or load from all backingStores?
        }
      }
    }
    return node.outs
  }
}

module.exports = { FrontGraph }
